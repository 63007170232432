/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import Page from "../../components/Page";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { VirtualTourTemplateProps } from "./data";
import * as classes from "./style.module.css";
import Section from "../../components/Section";
import Slideshow from "../../components/Slideshow";
import Video from "../../components/Video";
import SEO from "../../components/Seo";
import { SplashScreen } from "../../components/SplashScreen";

export default function ToursTemplate(props: VirtualTourTemplateProps): React.ReactElement {
    const videos = props.pageContext.tours;
    // const videos = [
    //     {
    //         media: {
    //             publicUrl: "https://www.youtube.com/watch?v=dKFz33zKE8k&list=PLJyFDeVp2PtMms-VQ8rwXPUrWK2JAzHfU"
    //         },
    //         title: "Tybee Island Design - Interior"
    //     },
    //     {
    //         media: {
    //             publicUrl: "https://www.youtube.com/watch?v=fQbY6wqjVPI&list=PLJyFDeVp2PtMms-VQ8rwXPUrWK2JAzHfU&index=2"
    //         },
    //         title: "Tybee Island Design - Exterior "
    //     },
    //     {
    //         media: {
    //             publicUrl: "https://www.youtube.com/watch?v=4z-pCORfl7Y&list=PLJyFDeVp2PtMms-VQ8rwXPUrWK2JAzHfU&index=3"
    //         },
    //         title: "Lola's Restaurant - Interior"
    //     },
    //
    // ]
    const slideshowData = videos.map((video, index) => {
        return {
            name: video.title,
            alt: video.description,
            // seoData: seoData,
            element: (
                <div className={classes.TourVideoContainer} key={index}>
                    <Video video={video} className={classes.TourVideo} />
                    <div>
                        <p className={classes.SlideDescription}>{video.description}</p>
                    </div>
                </div>
            ),
        };
    });
    return (
        <Suspense
            fallback={
                <div>
                    <SplashScreen />
                </div>
            }
        >
            <Page>
                <Section anchor="virtualTours" heading="Virtual Tours" additionalClass={classes.Walkthroughs}>
                    <article className={classes.Body}>
                        <section className={classes.Header}>
                            <h4>Virtual Tour</h4>
                        </section>
                        {/* Project content */}
                        <section>
                            <Slideshow
                                autoplay={false}
                                infinite={false}
                                transitionDuration={0}
                                slideshow={slideshowData}
                            />
                        </section>
                    </article>
                </Section>
            </Page>
        </Suspense>
    );
}

export const Head = (props: VirtualTourTemplateProps) => {
    const siteMetadata = useSiteMetadata();
    const videos = props.pageContext.tours;
    const canonicalPath = '/tours';
    const slideshowSeo = videos.map((video) => {
        return {
            title: 'Virtual Tours',
            titleTemplate: siteMetadata.titleTemplate,
            description: video.description,
            canonical: canonicalPath,
            openGraph: {
                title: video.title,
                type: 'video.other',
                url: video.media.publicUrl!!,
                description: video.description,
                videos: [
                    {
                        url: video.media.publicUrl!!,
                        alt: video.description,
                    },
                ],
            },
        };
    });
    return (
        <>
            <SEO {...slideshowSeo[0]} />
        </>
    );
};
