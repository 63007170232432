import * as React from 'react';
import Page from '../components/Page';
import SEO from '../components/Seo';
import { LegalSection } from '../sections/Legal';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function PrivacyPage() {
    return (
        <>
            <Page>
                <LegalSection sectionId="privacy" heading="Privacy Policy" />
            </Page>
        </>
    );
}

export const Head = () => {
    const siteMetadata = useSiteMetadata();
    const seoProps = {
        title: 'Privacy Policy',
        titleTemplate: siteMetadata.titleTemplate,
        description: 'Our Terms of Service and privacy policy for usage of the website',
        noindex: true,
        nofollow: true,
    };
    return (
        <>
            <SEO {...seoProps} />
        </>
    );
};
