import { graphql, useStaticQuery } from 'gatsby';
import { AllSettingsQueryResult } from '../types';

export interface SiteConfiguration {
    logo: {
        text: string;
    };
    navigation: {
        footer: {
            label: string;
            url: string;
        }[];
        header: {
            label: string;
            url: string;
        }[];
    };
}

export function useSiteConfiguration(): SiteConfiguration {
    const data: AllSettingsQueryResult<{ siteConfiguration: SiteConfiguration }> = useStaticQuery(query);
    return data.allSettingsJson.settings[0].siteConfiguration;
}

export const query = graphql`
    query SiteConfiguration {
        allSettingsJson: allContentJson {
            settings: nodes {
                siteConfiguration {
                    logo {
                        text
                    }
                    navigation {
                        footer {
                            label
                            url
                        }
                        header {
                            label
                            url
                        }
                    }
                }
            }
        }
    }
`;
