// extracted by mini-css-extract-plugin
export var BackdropBlurred = "style-module--BackdropBlurred--2b497";
export var Burger = "style-module--Burger--9f847";
export var ContentWrapper = "style-module--ContentWrapper--d8f1c";
export var CtaButton = "style-module--CtaButton--6d19b";
export var DarkModeIconOff = "style-module--DarkModeIconOff--56a61";
export var DarkModeIconOn = "style-module--DarkModeIconOn--d7070";
export var DarkModeToggleMenu = "style-module--DarkModeToggleMenu--48525";
export var DarkModeToggleMenuItem = "style-module--DarkModeToggleMenuItem--b180e";
export var Header = "style-module--Header--76a99";
export var HeaderLogo = "style-module--HeaderLogo--57275";
export var NavLink = "style-module--NavLink--8b7f0";
export var SideBarBackdrop = "style-module--SideBarBackdrop--8f1cc";
export var SideBarWrapper = "style-module--SideBarWrapper--b3949";
export var SideNavigationBar = "style-module--SideNavigationBar--fedf2";
export var TopNavigationBar = "style-module--TopNavigationBar--2200d";
export var footer = "style-module--footer--09c3f";