export const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;
export const FORM_ENDPOINT = process.env.GATSBY_FORM_API_ENDPOINT;
export interface IFormField {
    name: string;
    text: string;
    fieldType?: string;
    inputMode?: 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
    maxLength?: number;
    isRequired: boolean;
}
export const defaultHeadline = `Ask an Architect`;
export const defaultSubHeadline = `Schedule a discovery call with an architect or ask about one of our services`;
export const defaultButtonText = `Submit`;
export const defaultSuccessUrl = '/project';
export const defaultFormFields: IFormField[] = [
    {
        name: 'firstName',
        text: 'First Name',
        maxLength: 128,
        isRequired: true,
    },

    {
        name: 'lastName',
        text: 'Last Name',
        maxLength: 128,
        isRequired: true,
    },

    {
        name: 'emailAddress',
        text: 'Email Address',
        inputMode: 'email',
        fieldType: 'email',
        maxLength: 320,
        isRequired: true,
    },

    {
        name: 'phoneNumber',
        text: 'Phone Number',
        maxLength: 18,
        isRequired: true,
    },

    {
        name: 'projectDetails',
        text: 'Project Details / Questions',
        fieldType: 'textarea',
        maxLength: 512,
        isRequired: true,
    },
];
