import React from 'react';
import Section from '../../components/Section';
import { LinkHandler, LinkType } from '../../components/LinkHandler';
import { PageSection } from '../../types';
import { useLocalDataSource } from './data';
import * as classes from './style.module.css';
import Animation from '../../components/Animation';

export default function OurValuesSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allOurValuesJson.sections[0];
    const [shownValues, setShownValues] = React.useState<number>(data.button.initiallyShownOurValues);
    function loadMoreHandler() {
        setShownValues(data.ourValues.length);
    }

    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading}>
                <div className={classes.OurValues}>
                    {data.ourValues.slice(0, shownValues).map((ourValue, key) => {
                        const iconImage = key % 2 == 0 ? '/icon.png' : '/icon-invert.png';
                        return (
                            <Animation key={key} className={classes.OurValue} type="scaleIn" delay={key * 100}>
                                <img
                                    src={iconImage}
                                    className={classes.Icon}
                                    alt="Baldwin Architectural Group Icon"
                                    height="35rem"
                                    width="35rem"
                                />{' '}
                                {ourValue.label}
                            </Animation>
                        );
                    })}
                    {shownValues < data.ourValues.length && (
                        <Animation type="scaleIn" delay={(shownValues + 1) * 100}>
                            <LinkHandler type={LinkType.BUTTON} onClickHandler={loadMoreHandler} label="+ Load more" />
                        </Animation>
                    )}
                </div>
            </Section>
        </Animation>
    );
}
