import React from 'react';
import * as classes from './style.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

interface LogoProps {
    className?: string;
}

export function Logo(props: LogoProps): React.ReactElement {
    const logoAsset = useStaticQuery(graphql`
        query AllContentfulImage {
            logo: contentfulImage(name: { regex: "/logo/" }) {
                alt
                data {
                    gatsbyImageData(layout: CONSTRAINED, quality: 5, width: 300)
                }
            }
        }
    `);
    const logo = logoAsset.logo;
    return (
        <div className={props.className ? props.className : classes.Logo} aria-roledescription="logo">
            <GatsbyImage
                image={logo.data.gatsbyImageData}
                alt={logo.alt ? logo.alt : logo.name}
                className={classes.LogoImage}
            />
        </div>
    );
}
