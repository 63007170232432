/* eslint-disable no-unused-vars */
import React from 'react';

import './src/globalStyles/global.css';
import './src/globalStyles/theme.css';
import '@fontsource/lato';
import '@fontsource/raleway';

import {GatsbyBrowser, Script} from 'gatsby';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
// This only works if in the modal page you add a
// Link to the homepage.
// @ts-ignore

export const onPrefetchPathname: GatsbyBrowser['onPrefetchPathname'] = ({loadPage}: { loadPage: any }): void => {
    if (typeof window !== 'undefined' && (window as any).indexPageData === undefined) {
        loadPage('/').then((result: any) => {
            (window as any).indexPageData = result;
            // If we load the modal page directly we need to
            // re-render by changing the state. Otherwise
            // the initial result is null for some reason.
            if ((window as any).setIndexPageData) (window as any).setIndexPageData();
        });
    }
};

// https://github.com/gatsbyjs/gatsby/issues/7454#issuecomment-4254QW03812
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] =
    ({routerProps: {location}, getSavedScrollPosition,}) => {
        return typeof window !== `undefined` ? getSavedScrollPosition(location) : null;
    };

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
    if (process.env.NODE_ENV !== 'production') {
        return null;
    }

    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    setTimeout(() => {
        if (typeof gtag === 'function') {
            gtag('event', 'page_view', { page_path: pagePath });
        }
    }, 100);
    return null
}
