import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import SkeletonLoader from 'tiny-skeleton-loader-react';

import { ProjectData } from '../../types';
import { LinkHandler, LinkType } from '../LinkHandler';
import * as classes from './style.module.css';

interface ProjectProps {
    data: ProjectData;
    showBanner?: boolean;
}

export default function ProjectCard(props: ProjectProps): React.ReactElement {
    const absoluteUrl = props.data.link.indexOf('://') > 0 || props.data.link.indexOf('//') === 0;
    return (
        <LinkHandler type={LinkType.LINK} externalLink={absoluteUrl} href={props.data.link} title={props.data.title}>
            <article className={classes.Card} style={{ border: '0.125rem solid var(--primary-color)' }}>
                {props.showBanner && (
                    <div className={classes.Banner}>
                        {props.data.banner && (
                            <GatsbyImage
                                className={classes.ImageWrapper}
                                image={props.data.banner.data.gatsbyImageData}
                                alt={props.data.banner.alt ? props.data.banner.alt : props.data.banner.name}
                            />
                        )}
                    </div>
                )}
                <div className={classes.DescriptionWrapper}>
                    <span className={classes.Title}>
                        <u>{props.data.title}</u>
                    </span>
                    <h4 className={classes.Category}>{props.data.categories.join(' / ')}</h4>
                    <div className={classes.Details}>
                        {formatDate(new Date(props.data.datePublished))}
                        {props.data.readTime.text && (
                            <span className={classes.ReadingTime}>{props.data.readTime.text}</span>
                        )}
                    </div>
                </div>
            </article>
        </LinkHandler>
    );
}

export function ProjectSkeleton(): React.ReactElement {
    return (
        <div className={classes.Card} style={{ border: '0.125rem solid var(--primary-color)' }}>
            <div className={classes.DescriptionWrapper}>
                <SkeletonLoader
                    style={{
                        height: '1.5rem',
                        marginBottom: '.5rem',
                        background: 'var(--tertiary-color)',
                    }}
                />
                <SkeletonLoader style={{ height: '4rem', backgroundColor: 'var(--tertiary-color)' }} />
                <SkeletonLoader
                    style={{
                        height: '.75rem',
                        width: '50%',
                        marginTop: '.5rem',
                        backgroundColor: 'var(--tertiary-color)',
                    }}
                />
            </div>
        </div>
    );
}

export function formatDate(date: Date): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}
