import { graphql, useStaticQuery } from 'gatsby';

import { ImageFileResponse, ImageResponse } from '../../types';

interface OurValuesSectionQueryResult {
    allOurValuesJson: {
        sections: {
            button: {
                initiallyShownOurValues: number;
                label: string;
                visible: boolean;
            };
            ourValues: {
                image: ImageFileResponse;
                label: string;
            }[];
        }[];
    };
    icons: {
        iconNodes: ImageResponse[];
    };
}

export const useLocalDataSource = (): OurValuesSectionQueryResult => {
    return useStaticQuery(graphql`
        query OurValuesSectionQuery {
            allOurValuesJson {
                sections: nodes {
                    button {
                        initiallyShownOurValues
                        label
                        visible
                    }
                    ourValues {
                        label
                    }
                }
            }
        }
    `);
};
