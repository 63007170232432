// Projects
export const PRODUCT_URL = '/project/';

// Services Pages
export const SOLUTION_URL = '/service/';

export const IMAGES_PATH = '/images/';

// About Page
export const ABOUT_URL = '/about/';

// CONTACT
export const CONTACT_URL = '#contact';

// GetForm.io
export const API_ENDPOINT = `${process.env.GATSBY_FORM_API_ENDPOINT}`;

export const APP_ROOT_ID = 'app-root';

export const CORS_ORIGINS: [string | undefined] = [''];
