import React from 'react';
import Section from '../../components/Section';
import Slider from '../../components/Slider';
import { LinkHandler, LinkType } from '../../components/LinkHandler';
import { Service } from '../../components/Service';
import { PageSection } from '../../types';
import { useLocalDataSource } from './data';
import * as classes from './style.module.css';
import Animation from '../../components/Animation';

export default function ServicesSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const { services } = response.allContentfulService;
    const sections = response.allServicesJson.sections;
    return (
        <Animation type="fadeIn">
            <Section anchor={props.sectionId}>
                <Animation className={classes.MoreServices} type="fadeIn">
                    <LinkHandler
                        className={classes.MoreServicesLink}
                        type={LinkType.LINK}
                        externalLink={false}
                        href={sections[0].button.url}
                        label={sections[0].button.label}
                    >
                        Services
                    </LinkHandler>
                </Animation>
                <Slider additionalClasses={[classes.Services]}>
                    {services.map(({ service }, key) => {
                        return <Service key={key} index={key} data={{ ...service, visible: true }} />;
                    })}
                </Slider>
            </Section>
        </Animation>
    );
}
