import React from 'react';
import * as classes from './style.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { LinkType, LinkHandler } from '../LinkHandler';
import { ServiceData } from '../../types';
import Animation from '../Animation';

interface ServiceProps {
    data: ServiceData;
    index: number;
}

export function Service(props: ServiceProps): React.ReactElement {
    const isDesktopBreakpoint = useMediaQuery('(min-width: 920px)');

    return (
        <Animation
            type="fadeUp"
            className={classes.Service}
            style={{
                flexDirection: isDesktopBreakpoint && props.index % 2 === 0 ? 'row-reverse' : undefined,
            }}
        >
            <div className={classes.ServiceDetails}>
                <span className={classes.Title}>
                    <LinkHandler
                        href={props.data.url}
                        title={props.data.title}
                        type={LinkType.LINK}
                        externalLink={false}
                    >
                        {props.data.title}
                    </LinkHandler>
                </span>
                <p>{props.data.description}</p>
            </div>
            {getUrl(props)}
        </Animation>
    );
}

function getUrl(props: ServiceProps): React.ReactElement | null {
    const image = props.data.banner ? (
        <GatsbyImage
            className={classes.ServiceImageWrapper}
            image={props.data.banner.data.gatsbyImageData}
            alt={props.data.banner.alt ? props.data.banner.alt : props.data.banner.name}
        />
    ) : null;

    if (!props.data.url) {
        return image;
    }

    const absoluteUrl = props.data.url.indexOf('://') > 0 || props.data.url.indexOf('//') === 0;

    return (
        <LinkHandler type={LinkType.LINK} externalLink={absoluteUrl} href={props.data.url} title={props.data.title}>
            {image}
        </LinkHandler>
    );
}
