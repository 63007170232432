/* eslint-disable no-unused-vars */
import React, { PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import * as classes from './style.module.css';

export enum LinkType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    LINK = 'link',
}

interface LinkProps extends PropsWithChildren {
    type: LinkType;
    label?: string;
    id?: string | number;
    href?: string;
    externalLink?: boolean;
    className?: string;
    ariaLabel?: string;
    disabled?: boolean | undefined;
    target?: string | undefined;
    rel?: string[] | undefined;
    active?: boolean;
    size?: 'sm' | 'lg';
    title?: string;
    onClickHandler?: () => void;
    activeStyle?: object;
    partiallyActive?: boolean;
}

export function LinkHandler(props: LinkProps): React.ReactElement {
    if (props.type === LinkType.LINK || typeof props.type === 'undefined') {
        if (!props.href) {
            throw new Error(`Button should be a ${props.type} but no URL is given!`);
        } else {
            let relList = new Array<string>();
            if (typeof props.rel === 'undefined') {
                relList.concat(['nofollow', 'noopener', 'noreferrer']);
            } else {
                relList = props.rel;
            }
            ['nofollow', 'noopener', 'noreferrer'].forEach((rel) => {
                if (!relList?.includes(rel)) {
                    relList.push(rel);
                }
            });

            if (props.externalLink) {
                return (
                    <div className={props.className}>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                            id={props.id ? props.id.toString() : undefined}
                            href={props.href}
                            target={props.target ? props.target : '_blank'}
                            rel={relList.join(' ')}
                            onClick={props.onClickHandler}
                            aria-label={props.ariaLabel}
                            title={props.title}
                            style={props.activeStyle}
                        >
                            {props.children ? props.children : props.label}
                        </a>
                    </div>
                );
            } else {
                return (
                    <div className={props.className}>
                        {/* @ts-ignore */}
                        <Link
                            to={props.href ? props.href : '/'}
                            aria-label={props.ariaLabel}
                            onClick={props.onClickHandler}
                            activeStyle={props.activeStyle}
                            partiallyActive={props.partiallyActive}
                            label={props.label}
                        >
                            {props.children ?? ''}
                        </Link>
                    </div>
                );
            }
        }
    } else {
        throw new Error(`Unknown button type specified.`);
    }
}
