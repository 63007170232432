import { Theme, ThemeStyles, ThemeUIContextValue, useThemeUI } from 'theme-ui';

const makeTheme = <T extends Theme>(t: T) => t;

const config = {
    initialColorModeName: 'default',
    useColorSchemeMediaQuery: true,
    useLocalStorage: true,
    useRootStyles: true,
    useCustomProperties: true,
    useBorderBox: true,
};

const borderWidths = {
    px: `1px`,
    0: `0`,
    2: `2px`,
    4: `4px`,
    8: `8px`,
};

const breakpoints = [`320px`, `480px`, `640px`, `768px`, `900px`, `1200px`];

const baseColors = {
    black: [`#000000`, `#090802`, `#0d0c06`],
    white: [`#ffffff`, `#f1f1ed`],
    gold: ['#d0c18d', '#c6b576', '#bda960', '#b39c4a', '#9c8840', `#857437`],
    red: [`#fff5f5`, `#fed7d7`, `#feb2b2`, `#fc8181`, `#f56565`, `#e53e3e`, `#c53030`, `#9b2c2c`, `#742a2a`],
    gray: [
        `#f7fafc`,
        `#edf2f7`,
        `#e2e8f0`,
        `#cbd5e0`,
        `#a0aec0`,
        `#718096`,
        `#4a5568`,
        `#2d3748`,
        `#1a202c`,
        `#333333`,
        '#262626',
    ],
    orange: [`#f95e10`, `#cf4905`, `#9b3704`, `#812d03`, `#341201`],
    blue: [`#0073d8`, `#004684`, `#00305A`, '#00223f', `#001d36`, `#001324`, `#000a12`],
    green: [`#376f85`],
    yellow: ['#F2F0CE', `#F2D750`, '#f0d032', `#d2b110`, `#967f0b`, `#5a4c07`, `#1e1902`],
};

const colors = {
    text: `#131315`,
    background: `#f6f4ee`,
    primary: `#131315`,
    secondary: `#d8cdb3`,
    theme: `#022a4b`,
    accent: `#d8cdb3`,
    muted: `#8899aa`,
    highlight: `#b59f69`,
    modes: {
        dark: {
            theme: `#b59f69`,
            primary: `#e1e6ea`,
            secondary: `#b59f69`,
            tertiary: `#8899aa`,
            background: `#131009`,
            text: `#e4dcc8`,
            subtext: `#f1f1ed`,
            card: `#29343d`,
            cardHover: `#1e485a`,
            scrollBar: `#b59f69`,
            lightButton: `#bfc9d2`,
            darkButton: `#ffcc00`,
            boxShadowHover: `#e4dcc8`,
            boxShadow: `#b59f69`,
            cookieBarPrimary: `#131315`,
            cookieLink: `#ffcc00`,
            cookieLinkHover: `#662eaf`,
            cookieLinkImprove: `#ffcc00`,
            cookieBarBackground: `#f6f4ee`,
            info: `#b59f69`,
            danger: `#ffcc00`,
            swiperTheme: `#b59f69`,
        },
        light: {
            theme: `#022a4b`,
            primary: `#131315`,
            secondary: `#b59f69`,
            tertiary: `#d8cdb3`,
            background: `#f6f4ee`,
            text: `#131315`,
            subtext: `#022a4b`,
            card: `#eee9dd`,
            cardHover: `#dcd3bc`,
            scrollBar: `#110e09`,
            lightButton: `#ffcc00`,
            darkButton: `#022a4b`,
            boxShadowHover: `#022a4b`,
            boxShadow: `#8899aa`,
            cookieBarPrimary: `#f8f6f1`,
            cookieLink: `#ffcc00`,
            cookieLinkHover: `#1e485a`,
            cookieLinkImprove: `#d8cdb3`,
            cookieBarBackground: '#090802',
            swiperTheme: `#b59f69`,
            info: `#b59f69`,
            danger: `#ffcc00`,
        },
    },
};

const fontSizes = [12, 14, 16, 18, 24, 32, 48, 64, 72];

const fonts = {
    body: 'Lato, sans-serif',
    heading: 'Raleway, sans-serif',
    // monospace: 'Menlo, monospace',
};

const fontWeights = {
    body: 400,
    heading: 700,
    bold: 700,
};

const letterSpacings = {
    tighter: `-0.05em`,
    tight: `-0.025em`,
    normal: `0`,
    wide: `0.025em`,
    wider: `0.05em`,
    widest: `0.1em`,
};

const lineHeights = {
    body: 1.5,
    heading: 1.125,
};

const transitions = {
    boxShadow: '0.3s ease-out',
};

const sizes = {
    px: `1px`,
    0: `0`,
    1: `0.25rem`,
    2: `0.5rem`,
    3: `0.75rem`,
    4: `1rem`,
    5: `1.25rem`,
    6: `1.5rem`,
    7: `1.75rem`,
    8: `2rem`,
    10: `2.5rem`,
    12: `3rem`,
    16: `4rem`,
    20: `5rem`,
    24: `6rem`,
    32: `8rem`,
    full: `100%`,
    screenHeight: `100vh`,
    screenWidth: `100vw`,
};

const text = {
    heading: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
    },
};

// const shadows = {
//     default: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
//     md: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
//     lg: `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
//     xl: `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)`,
//     '2xl': `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
//     inner: `inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)`,
//     outline: `0 0 0 3px rgba(66, 153, 225, 0.5)`,
//     none: `none`,
// };

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

const styles: ThemeStyles = {
    bodyFixed: {
        position: `fixed`,
        overflow: `hidden`,
    },
    '#___gatsby': {
        width: `100%`,
        height: `100%`,
    },
    '#gatsby-focus-wrapper': {
        width: `100%`,
        height: `100%`,
    },
    root: {
        boxSizing: 'border-box',
        backgroundColor: 'background',
        color: 'primary',
        borderRadius: `1rem`,
        pageWidth: `64rem`,
        pagePadding: `0 2.5rem`,
        headerHeight: `6.25rem`,
        footerHeight: `7.5rem`,
        durationLabelContainer: `1s`,
        durationLabelTriangle: `0.4s`,
        delayLabelContainer: `1s`,
        delayLabelTriangle: `1.2s`,
        fontFamily: 'body',
        fontWeight: 'body',
        lineHeight: 'body',
        width: '100%',
        height: '100%',
        backgrounColor: 'background',
        textRendering: 'optimizeLegibility',
        scrollBehavior: 'smooth',
        marginTop: '0rem',
        fixed: {
            position: `fixed`,
            overflow: `hidden`,
        },
    },
    a: {
        display: 'inline-block',
        textDecoration: 'none',
        textDecorationSkipInk: 'auto',
        color: 'subtext',
        cursor: 'pointer',
        '::hover': {
            outline: '0',
        },
        '::focus': {
            outlinColor: 'subtext',
            outlineStyle: 'dotted',
            outlineWidth: '2px',
        },
    },
    h1: {
        fontWeight: '700',
        fontSize: '1.75rem',
        lineHeight: '2rem',
        fontFamily: 'heading',
        color: 'primary',
        margin: '1rem 0',
    },
    h2: {
        fontWeight: '700',
        fontSize: '1.625rem',
        lineHeight: '2rem',
        fontFamily: 'heading',
        color: 'primary',
        margin: '2rem 0 1rem 0',
    },
    h3: {
        fontWeight: '700',
        fontSize: '1.375rem',
        lineHeight: '2rem',
        fontFamily: 'heading',
        color: 'primary',
        margin: '2rem 0 1rem 0',
    },
    h4: {
        fontWeight: '700',
        fontSize: '1.125rem',
        lineHeight: '2rem',
        fontFamily: 'heading',
        color: 'primary',
        margin: '2rem 0 1rem 0',
    },
    hr: {
        margin: '3rem auto',
        borderWidth: '0.05rem',
        color: 'tertiary',
        opacity: '0.1',
    },
    u: {
        textDecoration: 'none',
        boxShadow: `inset 0 -0.5em 0 tertiary`,
        transition: {
            boxShadow: '0.3s ease-out',
        },
        '::hover': {
            boxShadow: `inset 0 -1em 0 secondary`,
        },
    },
    img: {
        zIndex: 6,
    },
};

const radii = {
    none: `0`,
    sm: `0.125rem`,
    default: `0.25rem`,
    lg: `0.5rem`,
    full: `9999px`,
};

const zIndices = {
    auto: `auto`,
    0: `0`,
    10: `10`,
    20: `20`,
    30: `30`,
    40: `40`,
    50: `50`,
};

const theme: Theme = makeTheme({
    config,
    borderWidths,
    breakpoints,
    baseColors,
    colors,
    fontSizes,
    fonts,
    fontWeights,
    letterSpacings,
    lineHeights,
    sizes,
    text,
    // shadows,
    transitions,
    space,
    styles,
    radii,
    zIndices,
});

export type ExactTheme = typeof theme;

interface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {
    theme: ExactTheme;
}

export const useTheme = useThemeUI as unknown as () => ExactContextValue;

export default theme;
