import React from 'react';
import { IconBaldwinLogo } from '../Icon/IconBaldwinLogo';
import { defaultContent, defaultHeadline, defaultSubHeadline, ContactSubmitFormSuccessTestId } from './constants';
import * as classes from './style.module.css';

export interface Props {
    headline?: React.ReactNode;
    subHeadline?: React.ReactNode;
    content?: React.ReactNode;
    button?: React.ReactNode;
}

export const ContactSubmitFormSuccess: React.FC<Props> = ({
    headline = defaultHeadline,
    subHeadline = defaultSubHeadline,
    content = defaultContent,
}) => {
    return (
        <section data-testid={ContactSubmitFormSuccessTestId} className={classes.SubmitFormSuccessWrapper}>
            <h3>{headline}</h3>
            <h4>{subHeadline}</h4>
            <div>{content}</div>
            <div>
                <IconBaldwinLogo width={180} />
            </div>
        </section>
    );
};

export default ContactSubmitFormSuccess;
