import { graphql, useStaticQuery } from 'gatsby';

import { ProjectQueryResponse } from '../../types';

export const useLocalDataSource = (): ProjectQueryResponse => {
    return useStaticQuery(graphql`
        query ProjectPreviewQuery {
            allContentfulArticle {
                projects: edges {
                    project: node {
                        contentfulId: contentful_id
                        categories
                        date: createdDate
                        datePublished
                        id
                        title
                        bodyRichText {
                            raw
                        }
                        banner {
                            alt
                            name
                            data {
                                gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 50, placeholder: BLURRED)
                            }
                        }
                        slug
                    }
                }
            }
            allSitePage(filter: { path: { regex: "/project/[-_a-zA-Z]+/" } }) {
                projectMetadata: edges {
                    metadata: node {
                        pageContext
                        path
                    }
                }
            }
        }
    `);
};
