/* eslint-disable no-unused-vars */
import React, {Suspense} from 'react';
import {InitializeColorMode, ThemeUIProvider} from 'theme-ui';
import {useTheme} from '../gatsby-plugin-theme-ui';
import Page from '../components/Page';
import {SplashScreen} from '../components/SplashScreen';

const HeroSection = React.lazy(() => import('../sections/Hero'));
const ServicesSection = React.lazy(() => import('../sections/Services'));
const ProjectsSection = React.lazy(() => import('../sections/Projects'));
const OurValuesSection = React.lazy(() => import('../sections/OurValues'));
const AboutSection = React.lazy(() => import('../sections/About'));
const ContactSection = React.lazy(() => import('../sections/Contact'));

const IndexPage = () => {
    const {theme} = useTheme();
    return (
        <React.StrictMode>
            <ThemeUIProvider theme={theme}>
                <InitializeColorMode/>
                <Suspense
                    fallback={
                        <div>
                            <SplashScreen/>
                        </div>
                    }
                >
                    <Page>
                        <HeroSection sectionId="hero"/>
                        <ServicesSection sectionId="services" heading="Services"/>
                        <ProjectsSection sectionId="projects" heading="Projects"/>
                        <AboutSection sectionId="about" heading="About Us"/>
                        <OurValuesSection sectionId="our-values" heading="Values"/>
                        <ContactSection sectionId="contact-us" heading="Free Discovery Call"/>
                    </Page>
                </Suspense>
            </ThemeUIProvider>
        </React.StrictMode>
    );
};

export default IndexPage;
