import React from 'react';
import Page from '../components/Page';
import Section from '../components/Section';
import SEO from '../components/Seo';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default function NotFoundPage(): React.ReactElement {
    return (
        <>
            <Page>
                <Section heading="Not Found" anchor="404">
                    <p>We&apos;re sorry, this page is not a part of the site&apos;s architectural design.</p>
                </Section>
            </Page>
        </>
    );
}

export const Head = () => {
    const siteMetadata = useSiteMetadata();
    return (
        <>
            <SEO
                {...{
                    title: '404: Not found',
                    titleTemplate: siteMetadata.titleTemplate,
                    noindex: true,
                    nofollow: true,
                }}
            />
        </>
    );
};
