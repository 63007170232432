import { graphql, useStaticQuery } from 'gatsby';

import { ImageResponse, SocialProfile } from '../../types';

interface ContactSectionQueryResult {
    contentfulAuthor: {
        description: string;
        email: string;
        name: string;
        avatar: ImageResponse;
        socialProfiles: [SocialProfile];
    };
}

const useLocalDataSource = (): ContactSectionQueryResult => {
    return useStaticQuery(graphql`
        query ContactSectionQuery {
            contentfulAuthor(fullName: { eq: "Christopher Baldwin" }) {
                name: fullName
                email
                avatar {
                    data {
                        gatsbyImageData(layout: CONSTRAINED, width: 200)
                    }
                    alt
                    name
                }
                socialMediaProfiles {
                    platform
                    handle
                    url
                    visible
                }
            }
        }
    `);
};

export default useLocalDataSource;
