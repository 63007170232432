import { LinkType, LinkHandler } from '../LinkHandler';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { CONTACT_URL } from '../../context/constants';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useSiteConfiguration } from '../../hooks/useSiteConfiguration';
import { useSticky } from '../../hooks/useSticky';
import ContactUsModal from '../ContactUsModal';
import DarkModeToggle from '../DarkModeToggle';
import { Logo } from '../Logo';
import * as classes from './style.module.css';
import Animation from '../Animation';
import { useLocation } from '@reach/router';

// import { Link } from 'gatsby';
const Header = (): React.ReactElement => {
    const [headerBarRef] = useSticky<HTMLDivElement>();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (location.hash) {
            if (location.hash === CONTACT_URL) {
                setShowModal(true);
            }
        }
    }, [location]);
    const onClose = () => {
        if (typeof document !== 'undefined' && window && window.history) {
            window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
            setShowModal(false);
        }
    };

    const style = {
        position: 'sticky',
        top: 0,
        zIndex: 100,
    } as const;
    const [open, setOpen] = React.useState<boolean>(false);
    const siteConfiguration = useSiteConfiguration();
    const isDesktopBreakpoint = useMediaQuery('(min-width: 920px)');
    const navigationItems = (
        <>
            <ul>
                {siteConfiguration.navigation.header.map((linkObject, key) => {
                    return (
                        <LinkHandler
                            key={key}
                            href={linkObject.url}
                            className={classes.NavLink}
                            type={LinkType.LINK}
                            externalLink={false}
                            onClickHandler={!isDesktopBreakpoint ? () => setOpen(!open) : undefined}
                        >
                            <li>{linkObject.label}</li>
                        </LinkHandler>
                    );
                })}
            </ul>
            {
                <Button
                    className={classes.CtaButton}
                    href={CONTACT_URL}
                    onClick={!isDesktopBreakpoint ? () => setOpen(!open) : undefined}
                >
                    Contact Us
                </Button>
            }
            <DarkModeToggle className={classes.DarkModeToggleMenu} />
        </>
    );

    const sideNavigationBar = (
        <>
            <div className={classes.Burger} onClick={() => setOpen(!open)}>
                <div style={open ? { transform: 'rotate(45deg)' } : undefined} />
                <div style={open ? { transform: 'translateX(20px)', opacity: 0 } : undefined} />
                <div style={open ? { transform: 'rotate(-45deg)' } : undefined} />
            </div>
            <div
                className={classes.SideBarWrapper}
                style={open ? { transform: 'translateX(0)', visibility: 'visible', display: 'flex' } : { display: 'none' }}
                aria-hidden={!open}
                tabIndex={open ? 1 : -1}
            >
                <nav className={classes.SideNavigationBar}>{navigationItems}</nav>
            </div>
            <div
                className={classes.SideBarBackdrop}
                style={open ? { display: 'block' } : { display: 'none' }}
                onClick={() => setOpen(!open)}
            />
        </>
    );

    const topNavigationBar = <nav className={classes.TopNavigationBar}>{navigationItems}</nav>;

    return (
        <div ref={headerBarRef} style={style}>
            <header className={classes.Header}>
                {/* Make background blurry when sidebar is opened */}
                <Helmet
                    bodyAttributes={{
                        class: (!isDesktopBreakpoint && open) || showModal ? classes.BackdropBlurred : undefined,
                    }}
                ></Helmet>
                <ContactUsModal show={showModal} onHide={onClose} />
                <Animation className={classes.ContentWrapper} type="fadeDown">
                    <LinkHandler href="/" aria-label="home" type={LinkType.LINK} externalLink={false}>
                        <Logo className={classes.HeaderLogo} />
                    </LinkHandler>
                    {isDesktopBreakpoint ? topNavigationBar : sideNavigationBar}
                </Animation>
            </header>
        </div>
    );
};

export default Header;
