import React from 'react';
import { Logo } from '../Logo';
// import useDarkMode from 'use-dark-mode';
import { useSiteConfiguration } from '../../hooks/useSiteConfiguration';
import * as classes from './style.module.css';
import { LinkHandler, LinkType } from '../LinkHandler';

export function Footer(): React.ReactElement {
    const siteConfiguration = useSiteConfiguration();

    return (
        <footer className={classes.Footer}>
            <div className={classes.ContentWrapper}>
                <LinkHandler type={LinkType.LINK} externalLink={false} href="/" aria-label="home">
                    <Logo className={classes.FooterLogo} />
                </LinkHandler>
                <div className={classes.Links}>
                    {siteConfiguration.navigation.footer.map((linkObject, key) => {
                        return (
                            <LinkHandler
                                key={key}
                                type={LinkType.LINK}
                                externalLink={false}
                                href={linkObject.url}
                                aria-label={linkObject.label}
                                className={classes.Links}
                            >
                                {linkObject.label}
                            </LinkHandler>
                        );
                    })}
                    <LinkHandler
                        key="termly-display-preferences"
                        className="termly-display-preferences"
                        href="#"
                        externalLink={true}
                        aria-label="cookie preferences"
                        type={LinkType.LINK}
                    >
                        Cookie Preferences
                    </LinkHandler>
                </div>
            </div>
        </footer>
    );
}
