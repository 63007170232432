/* eslint-disable no-unused-vars */
import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { VideoContent } from '../../templates/Tours/data';
import { VideoSeoData } from '../../types';

export interface VideoProps {
    video: VideoContent;
    className: any;
}

const Video = (props: VideoProps) => {
    return (
        <>
            <video className={props.className} controls>
                <source
                    src={props.video.media.publicUrl}
                    title={props.video.title}
                    about={props.video.description}
                    type="video/mp4"
                />
            </video>
        </>
    );
};

export default Video;
