import React from 'react';

export const ContactSubmitFormSuccessTestId = 'ContactSubmitFormSuccess';
export const defaultHeadline = (
    <>
        <span>You&apos;re All Set! </span> <span className="text-primary"></span>
    </>
);
export const defaultSubHeadline = `You're signed up for our mailing list!`;
export const defaultContent = `We'll keep you up to date on the status of your quote and project.`;
