import React from 'react';
import Section from '../../components/Section';
import { PageSection } from '../../types';
import { privacyPolicy } from './privacy';

export function LegalSection(props: PageSection): React.ReactElement {

    return (
        <Section anchor={props.sectionId} heading={props.heading}>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy().html }} />
        </Section>
    );
}
