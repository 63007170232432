/* eslint-disable no-unused-vars */
import React, {Suspense} from 'react';
import {BLOCKS, INLINES, MARKS} from '@contentful/rich-text-types';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import AuthorSnippet from '../../components/AuthorSnippet';
import {LinkHandler, LinkType} from '../../components/LinkHandler';
import Page from '../../components/Page';
import {AllSeoProps} from '../../components/Seo/helpers';
import Slideshow from '../../components/Slideshow';
import {useSiteMetadata} from '../../hooks/useSiteMetadata';
import {ProjectTemplateProps, SlideshowImage} from '../../types';
import * as classes from './style.module.css';
import SEO from '../../components/Seo';
import {SplashScreen} from '../../components/SplashScreen';

export default function ProjectTemplate(props: ProjectTemplateProps): React.ReactElement {
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <strong>{text}</strong>,
            [MARKS.ITALIC]: (text) => <em>{text}</em>,
            [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
            [MARKS.CODE]: (text) => <code>{text}</code>,
        },
        renderNode: {
            [BLOCKS.DOCUMENT]: (node, children) => {
                return <span>{children}</span>;
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return <p>{children}</p>;
            },
            [INLINES.HYPERLINK]: ({data}, children) => {
                if (typeof window === 'undefined') {
                    return null;
                }
                return (
                    <span>
                        <a
                            href={data.uri}
                            target={`${
                                data.uri.startsWith(window.location.protocol.concat(window.location.host))
                                    ? '_self'
                                    : '_blank'
                            }`}
                            rel={`${
                                data.uri.startsWith(window.location.protocol.concat(window.location.host))
                                    ? ''
                                    : 'noopener noreferrer'
                            }`}
                        >
                            {children}
                        </a>
                    </span>
                );
            },
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                const {gatsbyImageData, description}: { gatsbyImageData: IGatsbyImageData; description: string } =
                    node.data.target;
                if (!gatsbyImageData) {
                    // asset is not an image
                    return <></>;
                }
                return <GatsbyImage alt={description ? description : 'embedded image'} image={gatsbyImageData}/>;
            },
        },
    };
    const {project} = props.data;
    const slideshowImages: SlideshowImage[] = project.slideshow?.images;
    // @ts-ignore
    const bannerImage = project.banner;
    const richText = renderRichText(project.bodyRichText, options);
    return (
        <Suspense
            fallback={
                <div>
                    <SplashScreen/>
                </div>
            }
        >
            <Page>
                <article className={classes.Project}>
                    <div className={classes.Breadcrumb}>
                        {/* @ts-ignore */}
                        <LinkHandler
                            type={LinkType.LINK}
                            externalLink={false}
                            href={props.pageContext.listingPagePath}
                            title="Back To Projects Listing"
                        >
                            <span className={classes.BackArrow}>&#10094;</span>
                            All Projects
                        </LinkHandler>
                    </div>
                    {/* Project content section */}
                    <section className={classes.Header}>
                        <span className={classes.Category}>{project.categories.join(' / ')}</span>
                        <h1>{project.title}</h1>
                        <div className={classes.Details}>
                            {project.datePublished}
                            <span className={classes.ReadingTime}>{props.pageContext.readTime.text}</span>
                        </div>
                    </section>
                    {/* Banner section */}
                    {getImage(bannerImage.data.gatsbyImageData) ? (
                        <section className={classes.Banner}>
                            <GatsbyImage
                                image={bannerImage.data.gatsbyImageData}
                                alt={bannerImage.alt ? bannerImage.alt : bannerImage.name}
                                className={classes.BannerImage}
                            />
                        </section>
                    ) : (
                        <></>
                    )}
                    {/* Project content */}
                    <div className={classes.Text}>{richText}</div>
                    <section className={classes.Body}>
                        <div className={classes.Content}>
                            {slideshowImages && (
                                <Slideshow
                                    slideshow={slideshowImages}
                                    elementCss={classes.Slideshow}
                                    autoplay={false}
                                    infinite={true}
                                    canSwipe={true}
                                    // slidesToShow={1}
                                    // transitionDuration={0}
                                    slidesToScroll={1}
                                    slideType={'slide'}
                                />
                            )}
                        </div>
                    </section>
                    <section className={classes.Footer}>
                        <AuthorSnippet
                            author={project.author.fullName}
                            avatar={project.author.avatar}
                            bio={project.author.bio.bio}
                        />
                    </section>
                </article>
            </Page>
        </Suspense>
    );
}

export const Head = (props: ProjectTemplateProps) => {
    const siteMetadata = useSiteMetadata();
    const {project} = props.data;
    const slideshowImages: SlideshowImage[] = project.slideshow?.images;
    // @ts-ignore
    const bannerImage = project.banner;
    const openGraphImageProps = slideshowImages
        ? slideshowImages.map((slide) => {
            return {
                url: slide.data.publicUrl,
                width: slide.data.gatsbyImageData.width,
                height: slide.data.gatsbyImageData.height,
                alt: slide.alt ? slide.alt : slide.name,
            };
        })
        : [];
    openGraphImageProps.push({
        url: bannerImage.data.publicUrl,
        width: bannerImage.data.gatsbyImageData.width,
        height: bannerImage.data.gatsbyImageData.height,
        alt: bannerImage.alt ? bannerImage.alt : bannerImage.name,
    });
    const projectUrl = siteMetadata.siteUrl.concat(project.slug);
    // @ts-ignore
    const imageArray = slideshowImages
        ? slideshowImages.map((image) =>
            image.data ? (image.data.publicUrl ? image.data.publicUrl : undefined) : undefined,
        )
        : [];
    const seoProps: AllSeoProps = {
        projectProps: {
            url: projectUrl,
            headline: project.title,
            datePublished: project.datePublished,
            body: project.bodyRichText.raw,
            dateModified: project.lastModifiedTime,
            authorName: project.author.fullName,
            keywords: project.keywords,
            images: imageArray,
            publisherName: siteMetadata.publisher,
            publisherLogo: siteMetadata.logoUrl,
            description: project.description,
        },
        linkTags: [
            {
                rel: 'index',
                title: 'Baldwin Architectural Projects',
                href: 'https://www.baldwinarchitect.com/project',
            },
        ],
        title: project.title,
        description: project.description,
        titleTemplate: siteMetadata.titleTemplate,
        openGraph: {
            type: 'article',
            url: projectUrl,
            title: project.title,
            description: project.description,
            article: {
                publishedTime: project.datePublished,
                modifiedTime: project.lastModifiedTime,
                authors: siteMetadata.socialProfiles.map((profile) => profile.url),
                tags: project.keywords,
            },
            images: openGraphImageProps,
        },
    };
    return (
        <>
            <SEO {...seoProps} />
        </>
    );
};

export const query = graphql`
    query ($contentfulId: String) {
        project: contentfulArticle(contentful_id: { eq: $contentfulId }) {
            contentfulId: contentful_id
            categories
            description
            title
            lastModifiedTime: updatedAt
            datePublished(formatString: "MM-DD-Y")
            keywords
            slug
            slideshow {
                images {
                    alt
                    name
                    data {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            height: 512
                            quality: 50
                            placeholder: BLURRED
                            cornerRadius: 16
                        )
                    }
                }
                name
            }
            bodyRichText {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        description
                    }
                    gatsbyImageData(layout: CONSTRAINED, width: 1200, placeholder: BLURRED, resizingBehavior: SCALE)
                }
            }
            banner {
                alt
                name
                data {
                    gatsbyImageData(width: 1200, layout: CONSTRAINED, quality: 70, resizingBehavior: SCALE)
                }
            }
            author {
                fullName
                bio {
                    bio
                }
                avatar {
                    alt
                    name
                    data {
                        gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;
