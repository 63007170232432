import {  StaticImage } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Toggle from 'react-toggle';
import { useTheme } from '../../gatsby-plugin-theme-ui';
import * as classes from './style.module.css';

export default function DarkModeToggle(props: React.HTMLAttributes<HTMLInputElement>): React.ReactElement | null {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
        return null;
    }

    const onIcon = '../../images/DarkModeIconOn.png';
    const offIcon = '../../images/DarkModeIconOff.png';
    const { colorMode, setColorMode } = useTheme();
    const [isChecked, setChecked] = useState(colorMode === 'dark' ? true : false);
    (window as any).__setPreferredTheme(colorMode);

    const changeHandler = useCallback(
        (e: any): void => {
            const checked = e.target.checked;
            // setColorMode(mode);
            // const isChecked = next === 'dark' ? true : false;
            const mode = checked ? 'dark' : 'light';
            (window as any).__setPreferredTheme(mode);
            localStorage.setItem('preferred-theme', mode);
            document.documentElement.className = mode;
            setColorMode(mode);
            setChecked(checked);
            if (isChecked && colorMode !== 'dark') {
                console.log(`dark mode state does not match toggle - current: ${colorMode}`);
            }
        },
        [setChecked, setColorMode, colorMode, isChecked],
    );

    return (
        <>
            <Helmet
                bodyAttributes={{
                    'data-theme': colorMode,
                }}
            />
            <Toggle
                className={props.className}
                icons={{
                    checked: isChecked ? (
                        <StaticImage alt="Dark Mode on" src={onIcon} className={classes.DarkModeIconOn} />
                    ) : null,
                    unchecked: !isChecked ? (
                        <StaticImage alt="Dark Mode off" src={offIcon} className={classes.DarkModeIconOff} />
                    ) : null,
                }}
                onChange={changeHandler}
                {...props}
            />
        </>
    );
}
