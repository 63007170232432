import { graphql, useStaticQuery } from 'gatsby';
import { HeroSectionJson } from '../../types';

const heroDataSource = (): HeroSectionJson => {
    return useStaticQuery(graphql`
        query HeroSectionQuery {
            allHeroJson {
                heroContent: nodes {
                    description
                    intro
                    socialProfiles {
                        platform
                        handle
                        url
                        visible
                    }
                    subtitle {
                        highlight
                        prefix
                        suffix
                    }
                    title
                }
            }
            heroSlideshow: contentfulSlideshow(name: { eq: "title-page-slideshow" }) {
                images {
                    data {
                        gatsbyImageData(
                            quality: 90
                            width: 1275
                            height: 850
                            layout: CONSTRAINED
                            breakpoints: [300, 480, 750, 960, 1275]
                        )
                    }
                    alt
                    name
                }
            }
        }
    `);
};

export default heroDataSource;
