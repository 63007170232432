/* eslint-disable no-unused-vars */
import React from 'react';
import * as classes from './style.module.css';
import { SocialProfile } from '../../types';
import { Icon } from '../Icon';
import Slider from '../Slider';
import { LinkHandler, LinkType } from '../LinkHandler';

interface SocialProfilesProps {
    profiles: SocialProfile[];
}

export function SocialProfiles(props: SocialProfilesProps): React.ReactElement {
    // Enrich the social profile with data from the display name configuration file
    // as well as the site metadata from settings.json
    const shownProfiles = props.profiles.map((profile) => {
        return {
            id: profile.platform,
            label: profile.platform,
            url: profile.url,
            visible: profile.visible,
        };
    });

    return (
        <Slider>
            {shownProfiles.map((profile, key) => {
                const completeProfileData = profile.label && profile.url;
                return !completeProfileData ? null : (
                    <LinkHandler
                        type={LinkType.LINK}
                        key={key}
                        className={classes.Slider}
                        href={profile.url}
                        target="_blank"
                        rel={['nofollow', 'noopener', 'noreferrer']}
                        aria-label={profile.label}
                        externalLink={true}
                    >
                        <div className={classes.SocialProfile}>
                            {profile.visible ? <Icon name={profile.id} /> : undefined} {profile.label}
                        </div>
                    </LinkHandler>
                );
            })}
        </Slider>
    );
}
