import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Logo } from '../Logo';
import Animation from '../Animation';
import * as classes from './style.module.css';

export function SplashScreen(): React.ReactElement {
    const [shouldUnmount, setShouldUnmount] = React.useState<boolean>(false);

    return (
        <Animation
            className={classes.SplashScreen}
            type={shouldUnmount === false ? 'fadeIn' : 'fadeOut'}
            duration={150}
            fillMode="forwards"
        >
            <Helmet bodyAttributes={{ class: 'fixed' }} />
            <div className={classes.LogoWrapper}>
                <Animation
                    className={classes.Backdrop}
                    type="reduceHeight"
                    delay={800}
                    fillMode="forwards"
                    onAnimationEnd={() => {
                        // Wait 500ms and start unmounting the splash screen
                        // setTimeout(() => {
                        setShouldUnmount(true);
                        // }, 500);
                    }}
                />
                <Logo className={classes.SplashLogo} />
            </div>
        </Animation>
    );
}
