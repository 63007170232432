import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Fade } from 'react-slideshow-image';
import { SliderProps, SlideshowImage } from '../../types';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';

const Slideshow = (props: SliderProps): React.ReactElement => {
    return (
        <div className="slideshow-container">
            <Slider {...props} />
        </div>
    );
};

const Slider = (props: SliderProps) => {
    switch (props.slideType ? props.slideType : 'fade') {
        case 'fade':
            return (
                <Fade {...props}>
                    {props.slideshow?.map(
                        (image: SlideshowImage, index: number): React.ReactElement => (
                            <div className="slide-container" key={index}>
                                <div className="each-fade" key={index}>
                                    <div
                                        className={props.cssClass ? props.cssClass : 'image-container'}
                                        style={{ height: props.height, width: props.width }}
                                    >
                                        {image.element ? (
                                            image.element
                                        ) : image.data!!.gatsbyImageData ? (
                                            <GatsbyImage
                                                image={image.data!!.gatsbyImageData}
                                                alt={image.alt}
                                                className={props.elementCss}
                                            />
                                        ) : (
                                            <img
                                                src={image.data!!.publicUrl}
                                                alt={image.alt}
                                                className={props.elementCss}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ),
                    )}
                </Fade>
            );
        case 'slide':
            return (
                <Slide {...props} className="slideshow-wrapper">
                    {props.slideshow?.map((image, index) => (
                        <div className="each-slide" key={index}>
                            <div>
                                {image.element ? (
                                    image.element
                                ) : image.data!!.gatsbyImageData ? (
                                    <GatsbyImage image={image.data!!.gatsbyImageData} alt={image.alt} />
                                ) : (
                                    <img src={image.data!!.publicUrl} alt={image.alt} />
                                )}
                            </div>
                        </div>
                    ))}
                </Slide>
            );
        default:
            break;
    }
};

export default Slideshow;
