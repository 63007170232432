import React, { useState } from 'react';

export function useMediaQuery(query: string): boolean {
    const [isWindowUndefined] = useState(typeof window === 'undefined' || typeof window.matchMedia === 'undefined');
    if (isWindowUndefined) {
        return false;
    }
    const mediaQuery = window.matchMedia(query);
    const [match, setMatch] = React.useState<boolean>(mediaQuery.matches);
    React.useEffect(() => {
        function handleMatch() {
            setMatch(mediaQuery.matches);
        }
        try {
            mediaQuery.addEventListener('change', handleMatch);
        } catch (error) {
            console.error(`\nAdding mediaQuery event listener failed:\n${JSON.stringify(error)}\n`);
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return () => mediaQuery.removeEventListener('change', handleMatch);
        }
    }, []);

    return match;
}
