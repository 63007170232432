// extracted by mini-css-extract-plugin
export var BackArrow = "style-module--BackArrow--b7e3e";
export var Banner = "style-module--Banner--2b973";
export var BannerCaption = "style-module--BannerCaption--d2b19";
export var BannerImage = "style-module--BannerImage--8c0f5";
export var Body = "style-module--Body--ab474";
export var Breadcrumb = "style-module--Breadcrumb--29587";
export var Category = "style-module--Category--2d1eb";
export var Content = "style-module--Content--bd712";
export var Details = "style-module--Details--45766";
export var Footer = "style-module--Footer--74193";
export var Header = "style-module--Header--f3498";
export var Keyword = "style-module--Keyword--e668a";
export var Project = "style-module--Project--60320";
export var ReadingTime = "style-module--ReadingTime--ad9a6";
export var Service = "style-module--Service--4a15f";
export var Slideshow = "style-module--Slideshow--c8aa5";
export var Text = "style-module--Text--55eb9";