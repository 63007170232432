import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as classes from './style.module.css';
import { ImageResponse } from '../../types';

interface AuthorProps {
    author: string;
    avatar: ImageResponse;
    bio: string;
}

const AuthorSnippet = (props: AuthorProps): React.ReactElement => {
    const { author, avatar, bio } = props;
    return (
        <div className={classes.AuthorSnippet}>
            <GatsbyImage
                image={avatar.data.gatsbyImageData}
                alt={avatar.alt ? avatar.alt : avatar.name}
                className={classes.Avatar}
            />
            <div className={classes.Description}>
                <span className={classes.WrittenBy}>
                    Written By <u>{author}</u>
                </span>
                <p className={classes.Bio}>{bio}</p>
            </div>
        </div>
    );
};

export default AuthorSnippet;
