import { graphql, useStaticQuery } from 'gatsby';

import { ServicesSectionQueryResult } from '../../types';

export const useLocalDataSource = (): ServicesSectionQueryResult => {
    return useStaticQuery(graphql`
        query ServicesSectionQuery {
            allServicesJson {
                sections: nodes {
                    button {
                        label
                        url
                        visible
                    }
                    services {
                        category
                        description
                        image {
                            alt
                            src
                        }
                        tags
                        title
                        visible
                        url
                    }
                }
            }
            allContentfulService {
                services: edges {
                    service: node {
                        title
                        categories
                        description
                        keywords
                        url: slug
                        banner {
                            alt
                            name
                            data {
                                gatsbyImageData(width: 500, layout: CONSTRAINED, quality: 50, placeholder: BLURRED)
                            }
                        }
                    }
                }
            }
        }
    `);
};
