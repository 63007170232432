import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import {
    defaultButtonText,
    defaultFormFields,
    defaultHeadline,
    defaultSubHeadline,
    defaultSuccessUrl,
    IFormField,
    RECAPTCHA_SITE_KEY,
} from './constants';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormSuccessModalView from '../FormSuccessModalView';
import Section from '../Section';
import { load } from 'recaptcha-v3';
import { LinkType, LinkHandler } from '../LinkHandler';
import { Logo } from '../Logo';
import { API_ENDPOINT } from '../../context/constants';
import * as classes from './style.module.css';

interface ContactUsFormProps {
    onCancel: () => void;
    list?: string;
    formFields?: IFormField[];
    subHeadline?: React.ReactNode;
    headline?: React.ReactNode;
    buttonText?: string;
    successSubHeadline?: string;
    successContent?: string;
    successBtnText?: string;
    successBtnUrl?: string;
}

interface IFormContactInformation {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    phoneNumber?: string;
    projectDetails?: string;
    page?: string;
}

const getContactFormInitialState: IFormContactInformation = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    projectDetails: '',
    page: '',
};

const ContactUsForm = ({
    onCancel,
    formFields = defaultFormFields,
    subHeadline = defaultSubHeadline,
    headline = defaultHeadline,
    buttonText = defaultButtonText,
    successSubHeadline,
    successContent,
    successBtnText,
    successBtnUrl = defaultSuccessUrl,
}: ContactUsFormProps) => {
    const [formState, setFormState] = useState(getContactFormInitialState);
    const [showSuccess, setShowSuccess] = useState(false);
    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setFormState({ ...formState, [e.target.name]: e.target.value } as IFormContactInformation);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        load(`${RECAPTCHA_SITE_KEY}`, {
            autoHideBadge: true,
        }).then((recaptcha) => {
            recaptcha.execute('submit').then((token) => {
                const formObject = e.target;
                if (formObject instanceof HTMLFormElement) {
                    const data = new FormData(formObject);
                    data.append('g-recaptcha-response', token);
                    fetch(API_ENDPOINT, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json, text/plain, */*',
                        },
                        body: data,
                    }).catch((err) => {
                        console.error(err);
                        alert(`\nthere was an error submitting the contact form...\nbody:\n${data}`);
                    });
                }
            });
        });
        setFormState(getContactFormInitialState);
        setShowSuccess(true);
    };
    const isValid =
        formState.lastName &&
        formState.firstName &&
        formState.emailAddress &&
        formState.phoneNumber &&
        formState.projectDetails;
    return (
        <div className={classes.ReactModalOverlay}>
            <Section anchor="contact">
                <>
                    <div className={classes.FormWrapper}>
                        <div className={classes.ModalLogoWrapper}>
                            <LinkHandler href="/" type={LinkType.LINK} externalLink={false}>
                                <Logo className={classes.ModalLogo} />
                            </LinkHandler>
                        </div>
                        <div>
                            {!showSuccess ? (
                                <>
                                    <div>
                                        <h3>{headline}</h3>
                                        <p>{subHeadline}</p>
                                    </div>
                                    <Form id="contact-form" onSubmit={submitHandler} className={classes.ModalForms}>
                                        {formFields.map(
                                            ({ name, text, fieldType, inputMode, maxLength, isRequired }, idx) => {
                                                return (
                                                    <Form.Group
                                                        controlId={`form${name}`}
                                                        key={idx}
                                                        className={classes.ModalFormFieldWrapper}
                                                    >
                                                        <Form.Control
                                                            as={fieldType == 'textarea' ? 'textarea' : 'input'}
                                                            inputMode={inputMode ? inputMode : 'text'}
                                                            name={name}
                                                            className={classes.ModalFormField}
                                                            value={formState[name as keyof IFormContactInformation]}
                                                            required={isRequired}
                                                            aria-required={isRequired}
                                                            onChange={handleInputChange}
                                                            type={fieldType}
                                                            placeholder={`${text}${isRequired ? '*' : ''}`}
                                                            maxLength={maxLength ? maxLength : 255}
                                                            aria-label={text}
                                                        />
                                                    </Form.Group>
                                                );
                                            },
                                        )}
                                        <Form.Text>* Required Field</Form.Text>
                                        <div className={classes.ButtonWrapper}>
                                            <Button
                                                id="contact-submit"
                                                disabled={!isValid}
                                                variant="primary"
                                                type="submit"
                                            >
                                                {buttonText}
                                            </Button>
                                            <Button onClick={onCancel} variant="secondary" type="button">
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className={classes.RecaptchaWrapper}>
                                            <div>
                                                This site is protected by reCAPTCHA and the Google
                                                <LinkHandler
                                                    type={LinkType.LINK}
                                                    externalLink={true}
                                                    href="https://policies.google.com/privacy"
                                                >
                                                    Privacy Policy
                                                </LinkHandler>
                                                and
                                                <LinkHandler
                                                    type={LinkType.LINK}
                                                    externalLink={true}
                                                    href="https://policies.google.com/terms"
                                                >
                                                    Terms of Service
                                                </LinkHandler>
                                                apply.
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            ) : (
                                <FormSuccessModalView
                                    subHeadline={successSubHeadline}
                                    content={successContent}
                                    btnText={successBtnText}
                                    btnUrl={successBtnUrl}
                                />
                            )}
                        </div>
                    </div>
                </>
            </Section>
        </div>
    );
};

interface ModalProps {
    show: boolean;
    onHide: () => void;
}

const ContactUsModal = ({ show, onHide }: ModalProps) => {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
        return null;
    }

    return (
        <div className={classes.ReactModal}>
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm onCancel={onHide} successBtnUrl="/project" />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ContactUsModal;
