import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Section from '../../components/Section';
import { useLocalDataSource } from './data';
import { PageSection } from '../../types';
import * as classes from './style.module.css';
import Animation from '../../components/Animation';

export default function AboutSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allAboutMarkdown.sections[0];
    const aboutImage = response.aboutImage;
    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading}>
                <div className={classes.About}>
                    <div className={classes.Description} dangerouslySetInnerHTML={{ __html: data.html }} />
                    <Animation type="fadeLeft" delay={300}>
                        <div className={classes.ImageWrapper}>
                            <GatsbyImage
                                image={aboutImage.data.gatsbyImageData}
                                className={classes.Image}
                                alt={aboutImage.alt ? aboutImage.alt : aboutImage.name}
                            />
                        </div>
                    </Animation>
                </div>
            </Section>
        </Animation>
    );
}
