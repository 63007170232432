/* eslint-disable no-unused-vars */
import { graphql, useStaticQuery } from 'gatsby';
import { AllSettingsQueryResult, SiteMetadataJson } from '../types';

export enum SocialProfilePlatform {
    TWITTER = 'Twitter',
    FACEBOOK = 'Facebook',
    LINKEDIN = 'LinkedIn',
    EMAIL = 'Mail',
}

export function useSiteMetadata(): SiteMetadataJson {
    const data: AllSettingsQueryResult<{ siteMetadata: SiteMetadataJson }> = useStaticQuery(query);
    return data.allSettingsJson.settings[0].siteMetadata;
}

export const query = graphql`
    query SiteMetadata {
        allSettingsJson: allContentJson {
            settings: nodes {
                siteMetadata {
                    author
                    publisher
                    description
                    language
                    siteUrl
                    logoUrl
                    title
                    socialProfiles {
                        platform
                        handle
                        url
                        visible
                        cardType
                    }
                    titleTemplate
                    localBusiness {
                        id
                        name
                        description
                        url
                        telephone
                        type
                        address {
                            streetAddress
                            addressLocality
                            addressRegion
                            postalCode
                            addressCountry
                        }
                        geo {
                            latitude
                            longitude
                        }
                        images
                    }
                }
            }
        }
    }
`;
