import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

function useEffectOnce(effect: EffectCallback, deps?: DependencyList) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof window !== `undefined`) {
        const destroyFunc = useRef<void | any>();
        const calledOnce = useRef(false);
        const renderAfterCalled = useRef(false);

        if (calledOnce.current) {
            renderAfterCalled.current = true;
        }

        useEffect(() => {
            if (calledOnce.current) {
                return;
            }

            calledOnce.current = true;
            destroyFunc.current = effect();

            return () => {
                if (!renderAfterCalled.current) {
                    return;
                }

                if (destroyFunc.current) {
                    destroyFunc.current();
                }
            };
        }, [deps]);
    }
}

export default useEffectOnce;
