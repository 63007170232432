import React from 'react';
import * as classes from './style.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import SkeletonLoader from 'tiny-skeleton-loader-react';
// import useDarkMode from 'use-dark-mode';
import { ImageResponse } from '../../types';
import { LinkHandler, LinkType } from '../LinkHandler';

export interface ServiceCardData {
    image: ImageResponse;
    title: string;
    link: string;
}

interface ServiceCardProps {
    data: ServiceCardData;
    showBanner?: boolean;
}

export function ServiceCard(props: ServiceCardProps): React.ReactElement {
    const absoluteUrl = props.data.link.indexOf('://') > 0 || props.data.link.indexOf('//') === 0;

    return (
        <LinkHandler type={LinkType.LINK} externalLink={absoluteUrl} href={props.data.link} title={props.data.title}>
            <article className={classes.ServiceCard}>
                {props.showBanner && (
                    <div className={classes.Banner}>
                        {props.data.image && (
                            <GatsbyImage
                                className={classes.ImageWrapper}
                                image={props.data.image.data.gatsbyImageData}
                                alt={props.data.image.alt ? props.data.image.alt : props.data.image.name}
                            />
                        )}
                    </div>
                )}
                <div className={classes.DescriptionWrapper}>
                    <span className={classes.Title}>
                        <u>{props.data.title}</u>
                    </span>
                </div>
            </article>
        </LinkHandler>
    );
}

export function ServiceCardSkeleton(): React.ReactElement {
    return (
        <div className={classes.ServiceCard} style={{ border: '0.125rem solid var(--primary-color)' }}>
            <div className={classes.DescriptionWrapper}>
                <SkeletonLoader
                    style={{
                        height: '1.5rem',
                        marginBottom: '.5rem',
                        backgroundColor: 'var(--tertiary-color)',
                    }}
                />
                <SkeletonLoader style={{ height: '4rem', backgroundColor: 'var(--tertiary-color)' }} />
                <SkeletonLoader
                    style={{
                        height: '.75rem',
                        width: '50%',
                        marginTop: '.5rem',
                        backgroundColor: 'var(--tertiary-color)',
                    }}
                />
            </div>
        </div>
    );
}
