import React, { JSXElementConstructor, ReactElement } from 'react';
import Layout from '../Layout';

interface PageProps {
    children:
        | ReactElement<any, string | JSXElementConstructor<any>>
        | ReactElement<any, string | JSXElementConstructor<any>>[];
}

export default function Page(props: PageProps): React.ReactElement {
    return (
        <>
            <div>
                <Layout>{props.children}</Layout>
            </div>
        </>
    );
}
