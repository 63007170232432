/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Page from '../../components/Page';
import * as classes from './style.module.css';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { ServiceTemplateProps } from '../../types';
import Slideshow from '../../components/Slideshow';
import SEO from '../../components/Seo';
import { SplashScreen } from '../../components/SplashScreen';

const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { gatsbyImageData, description } = node.data.target;
            if (!gatsbyImageData) {
                // asset is not an image
                return null;
            }
            return <GatsbyImage alt={description} image={gatsbyImageData} />;
        },
    },
};

export default function ServiceTemplate(props: ServiceTemplateProps): React.ReactElement {
    const { service } = props.pageContext;
    const slideshowData = service.slideshow.images;
    return (
        <Suspense
            fallback={
                <div>
                    <SplashScreen />
                </div>
            }
        >
            <Page>
                <article className={classes.Service}>
                    <div className={classes.Breadcrumb}>
                        {/* @ts-ignore */}
                        <Link to={props.pageContext.listingPagePath} title="Back To Services">
                            <span className={classes.BackArrow}>&#10094;</span>
                            Architectural Services
                        </Link>
                    </div>
                    <section className={classes.Header}>
                        <span className={classes.Category}>{service.categories.join(' / ')}</span>
                        <h1>{service.title}</h1>
                    </section>
                    {getImage(service.banner.data.gatsbyImageData) ? (
                        <section className={classes.Banner}>
                            <GatsbyImage
                                image={service.banner.data.gatsbyImageData}
                                alt={service.banner.alt ? service.banner.alt : service.banner.name}
                                className={classes.BannerImage}
                            />
                        </section>
                    ) : (
                        <></>
                    )}
                    <section className={classes.Body}>
                        <div className={classes.Content}>
                            {renderRichText(service.bodyRichText, options)}
                            <Slideshow
                                slideshow={slideshowData}
                                autoplay={false}
                                infinite={true}
                                canSwipe={true}
                                elementCss={classes.Slideshow}
                                // transitionDuration={0}
                                // slidesToShow={1}
                                // slidesToScroll={1}
                                slideType={'slide'}
                            />
                        </div>
                    </section>
                </article>
            </Page>
        </Suspense>
    );
}

export const Head = (props: ServiceTemplateProps) => {
    const { service } = props.pageContext;
    const siteMetadata = useSiteMetadata();
    const bannerImage = service.banner;
    const canonicalPath = service.slug;
    const siteUrl = siteMetadata.siteUrl.concat(canonicalPath);
    const slideshowData = service.slideshow.images;
    const openGraphImageProps = slideshowData
        ? slideshowData.map((slide) => {
              return {
                  url: slide.data.publicUrl,
                  width: slide.data.gatsbyImageData.width,
                  height: slide.data.gatsbyImageData.height,
                  alt: slide.alt,
              };
          })
        : [];
    openGraphImageProps.push({
        url: bannerImage.data.publicUrl,
        width: bannerImage.data.gatsbyImageData.width,
        height: bannerImage.data.gatsbyImageData.height,
        alt: bannerImage.alt,
    });
    const seoProps = {
        title: service.title,
        titleTemplate: siteMetadata.titleTemplate,
        description: service.description,
        openGraph: {
            url: siteUrl,
            title: service.title,
            description: service.description,
            images: openGraphImageProps,
        },
    };
    return (
        <>
            <SEO {...seoProps} />
        </>
    );
};
