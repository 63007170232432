import { MutableRefObject, useEffect, useState } from 'react';

export function useOnScreen<T>(ref: MutableRefObject<T | undefined>, threshold = 0.1): boolean {
    if (typeof document === 'undefined' || typeof window === 'undefined') {
        return null;
    }

    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const element = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                if (entry.isIntersecting) {
                    setIntersecting(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold,
            },
        );
        if (element && element instanceof Element) observer.observe(element);

        return () => {
            if (element && element instanceof Element) observer.unobserve(element);
        };
    }, []);

    return isIntersecting;
}
