import React, { Suspense } from 'react';
import Page from '../../components/Page';
import Section from '../../components/Section';
import { ServiceCard } from '../../components/ServiceCard';
import * as classes from './style.module.css';
import ContactSection from '../../sections/Contact';
import { ServiceListingTemplateProps } from '../../types';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import SEO from '../../components/Seo';
import { SplashScreen } from '../../components/SplashScreen';
// import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export default function ServiceListingTemplate(props: ServiceListingTemplateProps): React.ReactElement {
    const services = props.pageContext.services;

    // Check if at least one filter option is selected. If so, create an array of all service ids that
    // are selected based on the current filter option selection. We use this later on to easily check
    // which services to show.

    return (
        <Suspense
            fallback={
                <div>
                    <SplashScreen />
                </div>
            }
        >
            <Page>
                <Section anchor="serviceListing" heading="Services">
                    <div className={classes.Listing}>
                        {services.map(({ service }, key) => {
                            return (
                                <ServiceCard
                                    key={key}
                                    showBanner={true}
                                    data={{
                                        ...service,
                                        image: service.banner,
                                        link: service.slug,
                                    }}
                                />
                            );
                        })}
                    </div>
                    <ContactSection sectionId="contact" heading="Free Discovery Call" />
                </Section>
            </Page>
        </Suspense>
    );
}

export const Head = (props: ServiceListingTemplateProps) => {
    const services = props.pageContext.services;
    const siteMetadata = useSiteMetadata();
    const canonicalPath = '/service';
    const siteUrl = siteMetadata.siteUrl.concat(canonicalPath);
    const title = 'Services';
    const description = 'Community Developers, Urban Renewal Specialists, Commercial Designers and Project Managers';
    const openGraphImageProps = services.map(({ service }) => {
        return {
            url: service.banner.data.publicUrl,
            width: service.banner.data.gatsbyImageData.width,
            height: service.banner.data.gatsbyImageData.height,
            alt: service.banner.alt,
        };
    });

    const seoProps = {
        title: title,
        description: description,
        titleTemplate: siteMetadata.titleTemplate,
        siteUrl: siteUrl,
        canonical: canonicalPath,
        metaTags: [
            {
                property: 'description',
                content: description,
            },
            {
                property: 'title',
                content: title,
            },
        ],
        openGraph: {
            type: 'website',
            url: siteUrl,
            title: title,
            description: description,
            images: openGraphImageProps,
        },

        blogJsonLd: {
            url: siteUrl,
            headline: description,
            images: services.map(({ service }) => {
                return service.banner.data.publicUrl;
            }),
            posts: services.map(({ service }) => {
                return {
                    headline: service.title,
                    image: service.banner.data.publicUrl,
                };
            }),
            datePublished: services[0].service.date,
            dateModified: services[0].service.date,
        },
    };
    return (
        <>
            <SEO {...seoProps} />
        </>
    );
};
