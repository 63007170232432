// extracted by mini-css-extract-plugin
export var active = "style-module--active--f2904";
export var bgImg = "style-module--bg-img--59d8d";
export var bgTile = "style-module--bg-tile--52998";
export var boxGallery = "style-module--box-gallery--922f1";
export var boxPanel = "style-module--box-panel--94e7a";
export var current = "style-module--current--5b252";
export var effect1 = "style-module--effect-1--19aab";
export var effect2 = "style-module--effect-2--1b51e";
export var effect3 = "style-module--effect-3--e947f";
export var scaleDown = "style-module--scaleDown--511e2";