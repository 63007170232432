import React from 'react';
import {
    defaultBtnText,
    defaultBtnUrl,
    defaultContent,
    defaultSubHeadline,
    FormSuccessModalViewTestId,
} from './constants';
import { LinkHandler, LinkType } from '../../components/LinkHandler';
import { useSiteMetadata, SocialProfilePlatform } from '../../hooks/useSiteMetadata';
import { Icon } from '../Icon';
import ContactSubmitFormSuccess from '../ContactSubmitFormSuccess';
import * as classes from './style.module.css';

export interface FormSuccessModalViewProps {
    subHeadline?: string;
    content?: string;
    btnText?: string;
    btnUrl?: string;
}

export const FormSuccessModalView = ({
    subHeadline = defaultSubHeadline,
    content = defaultContent,
    btnText = defaultBtnText,
    btnUrl = defaultBtnUrl,
}: FormSuccessModalViewProps) => {
    const siteMetadata = useSiteMetadata();
    const TWITTER_URL =
        siteMetadata.socialProfiles.find((profile) => profile.platform === SocialProfilePlatform.TWITTER)?.url ||
        'https://www.twitter.com';
    const FACEBOOK_URL =
        siteMetadata.socialProfiles.find((profile) => profile.platform === SocialProfilePlatform.FACEBOOK)?.url ||
        'https://www.facebook.com';
    return (
        <section data-testid={FormSuccessModalViewTestId}>
            <ContactSubmitFormSuccess
                subHeadline={subHeadline}
                content={
                    <>
                        <div className={classes.SuccessModalWrapper}>
                            <div className={classes.FollowUsWrapper}>
                                <div>{content}</div>
                            </div>
                            <div>
                                <div className={classes.ProjectsButton}>
                                    <LinkHandler
                                        type={LinkType.LINK}
                                        externalLink={false}
                                        href={btnUrl}
                                        label={btnText}
                                    >
                                        Projects
                                    </LinkHandler>
                                </div>
                                <div className={classes.FollowUsWrapper}>
                                    <LinkHandler
                                        href={FACEBOOK_URL}
                                        type={LinkType.LINK}
                                        externalLink={true}
                                        className={classes.FollowUsLinkWrapper}
                                    >
                                        <Icon
                                            name="facebook"
                                            color={`var(--theme-color`}
                                            height={50}
                                            width={50}
                                            className={classes.FollowUsIcon}
                                        />
                                        <p>Follow us on Facebook</p>
                                    </LinkHandler>
                                    <LinkHandler
                                        href={TWITTER_URL}
                                        type={LinkType.LINK}
                                        externalLink={true}
                                        className={classes.FollowUsLinkWrapper}
                                    >
                                        <Icon
                                            name="twitter"
                                            color={`var(--theme-color`}
                                            height={50}
                                            width={50}
                                            className={classes.FollowUsIcon}
                                        />
                                        <p>Follow us on Twitter</p>
                                    </LinkHandler>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </section>
    );
};

export default FormSuccessModalView;
