import React from 'react';

interface IconFacebookProps {
    color?: string;
    width?: number;
    height?: number;
}

export default function IconFacebook(props: IconFacebookProps): React.ReactElement {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            fill={props.color || 'var(--primary-color)'}
            x="0px"
            y="0px"
            width={props.width ? props.width : 82.028}
            height={props.height ? props.height : 82.021}
            viewBox="0 0 82.028 82.021"
        >
            <g transform="translate(196.74 55.374)">
                <path
                    d="m-194.88 25.711c-.49264-.51508-1.1128-1.199-1.3782-1.5199-.42288-.51131-.48252-5.2676-.48252-38.477 0-27.212.0998-37.959.35394-38.123.19467-.12623.52047-.54584.724-.93248.20353-.38663.8766-.88612 1.4957-1.11.61912-.22386 1.1257-.52267 1.1257-.66404 0-.14137 16.792-.25704 37.315-.25704 24.662 0 37.315.10907 37.315.32165 0 .1769.2285.32165.50778.32165s1.1116.59186 1.8496 1.3153l1.3419 1.3152v75.691l-.96504 1.0902c-.53077.5996-1.1563 1.2871-1.3901 1.5278-.34255.35268-2.6131.43765-11.695.43765-10.145 0-11.251-.05081-11.075-.50878.10739-.27983.19525-7.2998.19525-15.6v-15.091h5.1279c2.963 0 5.2092-.13161 5.3206-.31175.10598-.17146.54575-2.9466.97728-6.1669.5774-4.309.69102-5.9679.43026-6.2821-.27027-.32563-1.7185-.4269-6.1052-.4269h-5.7508v-4.8566c0-7.6678.37807-8.0758 7.7203-8.3303l4.6644-.16167v-11.568l-1.2867-.22816c-2.5182-.44652-11.492-.29508-13.499.22783-4.8375 1.2599-8.2136 4.3572-10.156 9.3173-.68004 1.7366-.77367 2.6064-.93491 8.6846l-.17919 6.7547-4.9635.0891c-2.9918.05371-5.1322.22907-5.3881.44145-.61329.50894-.61329 11.939 0 12.448.25592.21238 2.3964.38774 5.3881.44145l4.9635.089101.167 31.039h-40.837z"
                    strokeWidth=".322"
                    stroke="#000"
                />

                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0489 304.73-4.5102-5.0748-.64004-16.175c-.35202-8.8964-.53886-73.236-.41519-142.98l.22484-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.4234 1.1308 2.5402 1.622 1.1168.49127 4.0899 2.7347 6.6068 4.9855l4.5762 4.0922.00039 142.96 4e-4 142.96-4.4155 4.8935c-2.4285 2.6914-5.0803 5.1955-5.8928 5.5646-2.0645.93786-82.298.97955-83.048.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.92-.32512 19.212-.62889 19.998-1.4154 1.891-1.891 7.1834-43.338 5.9486-46.586-.84829-2.2312-3.8366-2.5187-26.178-2.5187h-18.829l.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.011l-4.6939-.8174c-5.8211-1.0137-38.503-1.0471-46.378-.04747-12.189 1.5473-21.615 6.1978-30.357 14.978-6.5557 6.584-10.249 12.259-13.564 20.843-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.057.62813-19.083.91923-20.098 1.933-1.0375 1.0368-1.2266 3.5118-1.44 18.847-.13512 9.7096-.0256 20.003.24349 22.875.44801 4.7817.64896 5.2848 2.3885 5.9797 1.0656.42567 9.8166.92012 19.935 1.1264l18.036.36763.001 40.796c.00071 22.438.21303 48.751.47151 58.474l.46997 17.679h-154.31l-4.5102-5.0748z"
                    strokeWidth=".86929"
                    stroke="#000"
                />

                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0489 304.73-4.5102-5.0748-.64004-16.175c-.35202-8.8964-.53886-73.236-.41519-142.98l.22484-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.4234 1.1308 2.5402 1.622 1.1168.49127 4.0899 2.7347 6.6068 4.9855l4.5762 4.0922.00039 142.96 4e-4 142.96-4.4155 4.8935c-2.4285 2.6914-5.0803 5.1955-5.8928 5.5646-2.0645.93786-82.298.97955-83.048.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.92-.32512 19.212-.62889 19.998-1.4154 1.891-1.891 7.1834-43.338 5.9486-46.586-.84829-2.2312-3.8366-2.5187-26.178-2.5187h-18.829l.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.011l-4.6939-.8174c-5.8211-1.0137-38.503-1.0471-46.378-.04747-12.189 1.5473-21.615 6.1978-30.357 14.978-6.5557 6.584-10.249 12.259-13.564 20.843-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.057.62813-19.083.91923-20.098 1.933-1.0375 1.0368-1.2266 3.5118-1.44 18.847-.13512 9.7096-.0256 20.003.24349 22.875.44801 4.7817.64896 5.2848 2.3885 5.9797 1.0656.42567 9.8166.92012 19.935 1.1264l18.036.36763.001 40.796c.00071 22.438.21303 48.751.47151 58.474l.46997 17.679h-154.31l-4.5102-5.0748z"
                    strokeWidth=".86929"
                    stroke="#000"
                />

                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0489 304.73-4.5102-5.0748-.64004-16.175c-.35202-8.8964-.53886-73.236-.41519-142.98l.22484-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.4234 1.1308 2.5402 1.622 1.1168.49127 4.0899 2.7347 6.6068 4.9855l4.5762 4.0922.00039 142.96 4e-4 142.96-4.4155 4.8935c-2.4285 2.6914-5.0803 5.1955-5.8928 5.5646-2.0645.93786-82.298.97955-83.048.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.92-.32512 19.212-.62889 19.998-1.4154 1.891-1.891 7.1834-43.338 5.9486-46.586-.84829-2.2312-3.8366-2.5187-26.178-2.5187h-18.829l.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.011l-4.6939-.8174c-5.8211-1.0137-38.503-1.0471-46.378-.04747-12.189 1.5473-21.615 6.1978-30.357 14.978-6.5557 6.584-10.249 12.259-13.564 20.843-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.057.62813-19.083.91923-20.098 1.933-1.0375 1.0368-1.2266 3.5118-1.44 18.847-.13512 9.7096-.0256 20.003.24349 22.875.44801 4.7817.64896 5.2848 2.3885 5.9797 1.0656.42567 9.8166.92012 19.935 1.1264l18.036.36763.001 40.796c.00071 22.438.21303 48.751.47151 58.474l.46997 17.679h-154.31l-4.5102-5.0748z"
                    strokeWidth=".86929"
                    stroke="#000"
                />
                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0489 304.73-4.5102-5.0748-.64004-16.175c-.35202-8.8964-.53886-73.236-.41519-142.98l.22484-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.4234 1.1308 2.5402 1.622 1.1168.49127 4.0899 2.7347 6.6068 4.9855l4.5762 4.0922.00039 142.96 4e-4 142.96-4.4155 4.8935c-2.4285 2.6914-5.0803 5.1955-5.8928 5.5646-2.0645.93786-82.298.97955-83.048.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.92-.32512 19.212-.62889 19.998-1.4154 1.891-1.891 7.1834-43.338 5.9486-46.586-.84829-2.2312-3.8366-2.5187-26.178-2.5187h-18.829l.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.011l-4.6939-.8174c-5.8211-1.0137-38.503-1.0471-46.378-.04747-12.189 1.5473-21.615 6.1978-30.357 14.978-6.5557 6.584-10.249 12.259-13.564 20.843-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.057.62813-19.083.91923-20.098 1.933-1.0375 1.0368-1.2266 3.5118-1.44 18.847-.13512 9.7096-.0256 20.003.24349 22.875.44801 4.7817.64896 5.2848 2.3885 5.9797 1.0656.42567 9.8166.92012 19.935 1.1264l18.036.36763.001 40.796c.00071 22.438.21303 48.751.47151 58.474l.46997 17.679h-154.31l-4.5102-5.0748z"
                    strokeWidth=".86929"
                    stroke="#000"
                />
                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0489 304.73-4.5102-5.0748-.64004-16.175c-.35202-8.8964-.53886-73.236-.41519-142.98l.22484-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.4234 1.1308 2.5402 1.622 1.1168.49127 4.0899 2.7347 6.6068 4.9855l4.5762 4.0922.00039 142.96 4e-4 142.96-4.4155 4.8935c-2.4285 2.6914-5.0803 5.1955-5.8928 5.5646-2.0645.93786-82.298.97955-83.048.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.92-.32512 19.212-.62889 19.998-1.4154 1.891-1.891 7.1834-43.338 5.9486-46.586-.84829-2.2312-3.8366-2.5187-26.178-2.5187h-18.829l.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.011l-4.6939-.8174c-5.8211-1.0137-38.503-1.0471-46.378-.04747-12.189 1.5473-21.615 6.1978-30.357 14.978-6.5557 6.584-10.249 12.259-13.564 20.843-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.057.62813-19.083.91923-20.098 1.933-1.0375 1.0368-1.2266 3.5118-1.44 18.847-.13512 9.7096-.0256 20.003.24349 22.875.44801 4.7817.64896 5.2848 2.3885 5.9797 1.0656.42567 9.8166.92012 19.935 1.1264l18.036.36763.001 40.796c.00071 22.438.21303 48.751.47151 58.474l.46997 17.679h-154.31l-4.5102-5.0748z"
                    strokeWidth=".86929"
                    stroke="#000"
                />
                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0221 304.7-4.537-5.105-.60697-16.145c-.33384-8.8798-.50861-73.206-.38839-142.95l.21859-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.426 1.1319 2.546 1.6246s4.0932 2.7129 6.6071 4.9337l4.5708 4.0379v286.21l-4.4429 4.8034c-2.4436 2.6419-5.0954 5.1054-5.8928 5.4745-2.0265.93798-82.271.97969-83.021.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.393-.31364 19.218-.64322 19.909-1.3599 2.1513-2.2323 7.5277-45.759 5.8583-47.428-1.257-1.257-7.8003-1.6994-25.363-1.7148l-19.464-.017.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.028l-5.0658-.85447c-3.4988-.59014-11.729-.75104-26.607-.52013-24.465.37971-25.895.6126-36.897 6.0114-5.6725 2.7835-7.7788 4.3334-12.857 9.4601-6.5647 6.6273-10.257 12.31-13.566 20.876-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.058.6282-19.083.91915-20.098 1.9332-1.0627 1.0622-1.1943 3.5335-1.1943 22.419 0 11.674.23755 22.071.5279 23.104.29613 1.054 1.2369 2.0918 2.1428 2.3637.88823.26665 9.731.6724 19.651.90166l18.036.41684.001 39.719c.00071 21.846.2131 48.157.47177 58.469l.47031 18.75h-154.31l-4.537-5.105z"
                    strokeWidth=".86929"
                    stroke="#000"
                />
                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0221 304.7-4.537-5.105-.60697-16.145c-.33384-8.8798-.50861-73.206-.38839-142.95l.21859-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.426 1.1319 2.546 1.6246s4.0932 2.7129 6.6071 4.9337l4.5708 4.0379v286.21l-4.4429 4.8034c-2.4436 2.6419-5.0954 5.1054-5.8928 5.4745-2.0265.93798-82.271.97969-83.021.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.393-.31364 19.218-.64322 19.909-1.3599 2.1513-2.2323 7.5277-45.759 5.8583-47.428-1.257-1.257-7.8003-1.6994-25.363-1.7148l-19.464-.017.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.028l-5.0658-.85447c-3.4988-.59014-11.729-.75104-26.607-.52013-24.465.37971-25.895.6126-36.897 6.0114-5.6725 2.7835-7.7788 4.3334-12.857 9.4601-6.5647 6.6273-10.257 12.31-13.566 20.876-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.058.6282-19.083.91915-20.098 1.9332-1.0627 1.0622-1.1943 3.5335-1.1943 22.419 0 11.674.23755 22.071.5279 23.104.29613 1.054 1.2369 2.0918 2.1428 2.3637.88823.26665 9.731.6724 19.651.90166l18.036.41684.001 39.719c.00071 21.846.2131 48.157.47177 58.469l.47031 18.75h-154.31l-4.537-5.105z"
                    strokeWidth=".86929"
                    stroke="#000"
                />
                <path
                    transform="matrix(.26458 0 0 .26458 -196.74 -55.374)"
                    d="m6.0221 304.7-4.537-5.105-.60697-16.145c-.33384-8.8798-.50861-73.206-.38839-142.95l.21859-126.8 2.3879-3.721c1.8159-2.8296 3.5269-4.3656 7.1429-6.412l4.755-2.6911h140.2c94.525 0 140.37.23746 140.71.72882.2803.40085 1.426 1.1319 2.546 1.6246s4.0932 2.7129 6.6071 4.9337l4.5708 4.0379v286.21l-4.4429 4.8034c-2.4436 2.6419-5.0954 5.1054-5.8928 5.4745-2.0265.93798-82.271.97969-83.021.0431-.31461-.39285-.47748-26.62-.36193-58.283l.21008-57.569 18.997-.41397c14.393-.31364 19.218-.64322 19.909-1.3599 2.1513-2.2323 7.5277-45.759 5.8583-47.428-1.257-1.257-7.8003-1.6994-25.363-1.7148l-19.464-.017.0142-14.464c.0306-31.32 1.6385-33.497 25.457-34.467 5.4372-.22149 12.538-.58139 15.779-.79977l5.8928-.39705v-44.028l-5.0658-.85447c-3.4988-.59014-11.729-.75104-26.607-.52013-24.465.37971-25.895.6126-36.897 6.0114-5.6725 2.7835-7.7788 4.3334-12.857 9.4601-6.5647 6.6273-10.257 12.31-13.566 20.876-2.5923 6.7113-3.2972 13.742-3.9698 39.597l-.48458 18.627-18.903.73948c-16.058.6282-19.083.91915-20.098 1.9332-1.0627 1.0622-1.1943 3.5335-1.1943 22.419 0 11.674.23755 22.071.5279 23.104.29613 1.054 1.2369 2.0918 2.1428 2.3637.88823.26665 9.731.6724 19.651.90166l18.036.41684.001 39.719c.00071 21.846.2131 48.157.47177 58.469l.47031 18.75h-154.31l-4.537-5.105z"
                    strokeWidth=".86929"
                    stroke="#000"
                />
            </g>
        </svg>
    );
}
