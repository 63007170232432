/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
// import { AllSeoProps, LinkProps, MetaProps, HelmetProps, JsonLdComponentProps, getOpeningHoursSpecification, TitleProps } from './helpers';
import {
    WithContext,
    Thing,
    DayOfWeek as SchemaDayOfWeek,
    LocalBusiness,
    OpeningHoursSpecification as SchemaOpeningHoursSpecification,
    Organization,
    Blog,
    Article,
    BlogPosting,
    ProfessionalService,
} from 'schema-dts';
import useEffectOnce from '../../hooks/useEffectOnce';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { AllSeoProps, BASE_DEFAULTS, DeferSeoProps, HelmetProps, LinkProps, MetaProps, TitleProps } from './helpers';

// import { BASE_DEFAULTS, getOpeningHoursSpecification, JsonLdScriptHelper } from './helpers';

let DEFAULTS = { ...BASE_DEFAULTS };

// type LocalBusiness = Extract<SchemaLocalBusiness, object>;

export interface OpeningHoursSpecification {
    /**
     * The closing hour of the place or service on the given day(s) of the week,
     * in hh:mm:ss format.
     */
    closes?: string;

    /**
     * The day of the week for which these opening hours are valid.
     */
    dayOfWeek?: DayOfWeek | DayOfWeek[];

    /**
     * The opening hour of the place or service on the given day(s) of the week,
     * in hh:mm:ss format.
     */
    opens?: string;

    /**
     * The date when the opening hours becomes valid, in YYYY-MM-DD format.
     */
    validFrom?: string;

    /**
     * The date after when the opening hours is not valid, in YYYY-MM-DD format.
     */
    validThrough?: string;
}

type DayOfWeek =
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday'
    | 'Mon'
    | 'Tues'
    | 'Wed'
    | 'Thurs'
    | 'Fri'
    | 'Sat'
    | 'Sun'
    | 'PublicHolidays';

const converter = {
    Mon: 'Monday',
    Tues: 'Tuesday',
    Wed: 'Wednesday',
    Thurs: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
} as const;

// interface JsonLdHeadProps extends HelmetProps {
//     logoProps?: LogoJsonLdProps;
//     blogProps?: BlogJsonLdProps;
//     projectProps?: ArticleJsonLdProps;
//     localBusinessProps?: LocalBusinessJsonLdProps;
// }

interface JsonLdProps<GThing extends Thing> extends DeferSeoProps {
    /**
     * The props which conform to the JSON-LD Specification.
     */
    json: WithContext<GThing>;
}

const validDay = (day: DayOfWeek): SchemaDayOfWeek =>
    `http://schema.org/${converter[day as keyof typeof converter] ?? day}` as SchemaDayOfWeek;

const getDayOfWeek = (
    dayOfWeek: undefined | DayOfWeek | DayOfWeek[],
): SchemaDayOfWeek | SchemaDayOfWeek[] | undefined =>
    !dayOfWeek ? undefined : Array.isArray(dayOfWeek) ? dayOfWeek.map(validDay) : validDay(dayOfWeek);

const getOpeningHoursSpecification = (
    openingHours: undefined | OpeningHoursSpecification | OpeningHoursSpecification[],
): SchemaOpeningHoursSpecification | SchemaOpeningHoursSpecification[] | undefined => {
    if (!openingHours) {
        return undefined;
    }

    if (Array.isArray(openingHours)) {
        return openingHours.map<SchemaOpeningHoursSpecification>(({ dayOfWeek, ...rest }) => ({
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: getDayOfWeek(dayOfWeek),
            ...rest,
        }));
    }
    return {
        '@type': 'OpeningHoursSpecification',
        ...openingHours,
        dayOfWeek: getDayOfWeek(openingHours.dayOfWeek),
    };
};

function JsonLdScriptHelper<GThing extends Thing>({ defer, json }: JsonLdProps<GThing>): React.ReactElement {
    return (
        <>
            <script defer={defer} type="application/ld+json">
                {JSON.stringify(json, null, 2)}
            </script>
        </>
    );
}

function MetaTagElement(tag, key?) {
    return <meta {...tag} key={key} />;
}

function LinkTagElement(tag, key?) {
    return <link {...tag} key={key} />;
}

export function LogoJsonLDComponent({ url, logo, overrides = {}, defer = false }): React.ReactElement {
    const json: WithContext<Organization> = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url,
        logo,
        ...overrides,
    };
    return <JsonLdScriptHelper defer={defer} json={json} />;
}

export function BlogJsonLDComponent({
    url,
    headline,
    title,
    images = [],
    datePublished,
    dateModified = null,
    authorName,
    authorType = 'Person',
    keywords,
    description,
    publisherName,
    publisherLogo,
    posts = [],
    issn,
    overrides,
    defer = false,
}): React.ReactElement {
    const json: WithContext<Blog> = {
        '@context': 'https://schema.org',
        '@type': 'Blog',
        mainEntityOfPage: {
            '@type': 'Blog',
            '@id': url,
        },
        headline: headline ?? title,
        keywords,
        issn,
        image: images,
        datePublished: datePublished,
        dateModified: dateModified ?? datePublished,
        description: description,
        author: authorName
            ? {
                  '@type': authorType as any,
                  name: authorName,
              }
            : undefined,
        publisher: publisherName
            ? {
                  '@type': 'Organization',
                  name: publisherName,
                  logo: publisherLogo
                      ? {
                            '@type': 'ImageObject',
                            url: publisherLogo,
                        }
                      : undefined,
              }
            : undefined,
        blogPost: posts.map((post) => ({ '@type': 'BlogPosting', ...post })),
        ...overrides,
    };
    return <JsonLdScriptHelper defer={defer} json={json} />;
}

export function ProjectJsonLDComponent({
    url,
    headline,
    title,
    images = [],
    datePublished,
    dateCreated,
    dateModified = datePublished,
    authorType = 'Person',
    authorName,
    description,
    publisherName,
    publisherLogo,
    body,
    overrides = { '@type': 'BlogPosting' } as BlogPosting,
    keywords,
    speakable,
    defer = false,
}): React.ReactElement {
    const json: WithContext<Article> = {
        '@context': 'https://schema.org',
        '@type': overrides['@type'],
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': url,
        },
        headline: headline ?? title,
        image: images,
        datePublished,
        dateModified,
        dateCreated,
        author: {
            '@type': authorType as any,
            name: authorName,
        },
        publisher: {
            '@type': 'Organization',
            name: publisherName,
            logo: {
                '@type': 'ImageObject',
                url: publisherLogo,
            },
        },
        description: description,
        articleBody: body,
        speakable: speakable
            ? speakable.map((item) => ({
                  '@type': 'SpeakableSpecification',
                  ...item,
              }))
            : undefined,
        keywords: Array.isArray(keywords) ? keywords.join(', ') : keywords,
        ...overrides,
    };

    return <JsonLdScriptHelper defer={defer} json={json} />;
}

export function LocalBusinessJsonLDComponent({
    type = 'ProfessionalService' as ProfessionalService,
    id,
    name,
    description,
    url,
    telephone,
    address,
    geo,
    images,
    openingHours,
    rating,
    priceRange,
    overrides,
    defer = false,
}): React.ReactElement {
    const json: WithContext<LocalBusiness> = {
        '@context': 'https://schema.org',
        '@type': type,
        '@id': id,
        name,
        description,
        url,
        telephone,
        priceRange,
        image: images,
        geo: {
            '@type': 'GeoCoordinates',
            ...geo,
        },
        address: {
            '@type': 'PostalAddress',
            ...address,
        },
        aggregateRating: rating
            ? {
                  '@type': 'AggregateRating',
                  ...rating,
              }
            : undefined,
        openingHoursSpecification: getOpeningHoursSpecification(openingHours),
        ...overrides,
    };

    return <JsonLdScriptHelper defer={defer} json={json} />;
}

const SEO = (props: AllSeoProps): React.ReactElement => {
    const [[title, titleTemplate, titleText], setTitle] = useState([
        props.title,
        props.titleTemplate,
        (props.titleTemplate ?? '').replace('%s', props.title ?? ''),
    ]);

    useEffect(() => {
        const titleText = (titleTemplate ?? '').replace('%s', title ?? '');
        // This will run when the page first loads and whenever the title changes
        if (typeof document !== 'undefined') {
            document.title = titleText;
        }
    }, [title, titleTemplate, titleText]);

    const siteMetadata = useSiteMetadata();

    const meta: MetaProps[] = [];
    const link: LinkProps[] = [];

    const noindex = (props.noindex ?? DEFAULTS.noindex) || props.dangerouslySetAllPagesToNoIndex;
    const nofollow = (props.nofollow ?? DEFAULTS.nofollow) || props.dangerouslySetAllPagesToNoFollow;

    const indexTags = ['robots', 'googlebot'];
    if (noindex || nofollow) {
        if (props.dangerouslySetAllPagesToNoIndex) {
            DEFAULTS.noindex = true;
        }
        if (props.dangerouslySetAllPagesToNoFollow) {
            DEFAULTS.nofollow = true;
        }

        for (const name of indexTags) {
            meta.push({
                name,
                content: `${noindex ? 'noindex' : 'index'},${nofollow ? 'nofollow' : 'follow'}`,
            });
        }
    } else {
        for (const name of indexTags) {
            meta.push({ name, content: 'index,follow' });
        }
    }

    if (props.description) {
        meta.push({ name: 'description', content: props.description });
    }

    if (props.mobileAlternate) {
        link.push({
            rel: 'alternate',
            media: props.mobileAlternate.media,
            href: props.mobileAlternate.href,
        });
    }

    if (props.languageAlternates && props.languageAlternates.length > 0) {
        props.languageAlternates.forEach((languageAlternate) => {
            link.push({
                rel: 'alternate',
                key: `languageAlternate-${languageAlternate.hrefLang}`,
                hrefLang: languageAlternate.hrefLang,
                href: languageAlternate.href,
            });
        });
    }

    if (props.twitter) {
        if (props.twitter.cardType) {
            meta.push({ name: 'twitter:card', content: props.twitter.cardType });
        }

        if (props.twitter.site) {
            meta.push({ name: 'twitter:site', content: props.twitter.site });
        }

        if (props.twitter.handle) {
            meta.push({ name: 'twitter:creator', content: props.twitter.handle });
        }
    }

    if (props.facebook) {
        if (props.facebook.appId) {
            meta.push({ property: 'fb:app_id', content: props.facebook.appId });
        }
    }

    if (props.openGraph) {
        if (props.openGraph.url || props.canonical) {
            meta.push({
                property: 'og:url',
                content: props.openGraph.url ?? props.canonical,
            });
        }

        if (props.openGraph.type) {
            const type = props.openGraph.type.toLowerCase();

            meta.push({ property: 'og:type', content: type });

            if (type === 'profile' && props.openGraph.profile) {
                if (props.openGraph.profile.firstName) {
                    meta.push({
                        property: 'profile:first_name',
                        content: props.openGraph.profile.firstName,
                    });
                }

                if (props.openGraph.profile.lastName) {
                    meta.push({
                        property: 'profile:last_name',
                        content: props.openGraph.profile.lastName,
                    });
                }

                if (props.openGraph.profile.username) {
                    meta.push({
                        property: 'profile:username',
                        content: props.openGraph.profile.username,
                    });
                }

                if (props.openGraph.profile.gender) {
                    meta.push({
                        property: 'profile:gender',
                        content: props.openGraph.profile.gender,
                    });
                }
            } else if (type === 'book' && props.openGraph.book) {
                if (props.openGraph.book.authors?.length) {
                    props.openGraph.book.authors.forEach((author) => {
                        meta.push({
                            property: 'book:author',
                            content: author,
                        });
                    });
                }

                if (props.openGraph.book.isbn) {
                    meta.push({
                        property: 'book:isbn',
                        content: props.openGraph.book.isbn,
                    });
                }

                if (props.openGraph.book.releaseDate) {
                    meta.push({
                        property: 'book:release_date',
                        content: props.openGraph.book.releaseDate,
                    });
                }

                if (props.openGraph.book.tags?.length) {
                    props.openGraph.book.tags.forEach((tag) => {
                        meta.push({
                            property: 'book:tag',
                            content: tag,
                        });
                    });
                }
            } else if (type === 'article' && props.openGraph.article) {
                if (props.openGraph.article.publishedTime) {
                    meta.push({
                        property: 'article:published_time',
                        content: props.openGraph.article.publishedTime,
                    });
                }

                if (props.openGraph.article.modifiedTime) {
                    meta.push({
                        property: 'article:modified_time',
                        content: props.openGraph.article.modifiedTime,
                    });
                }

                if (props.openGraph.article.expirationTime) {
                    meta.push({
                        property: 'article:expiration_time',
                        content: props.openGraph.article.expirationTime,
                    });
                }

                if (props.openGraph.article.authors?.length) {
                    props.openGraph.article.authors.forEach((author) => {
                        meta.push({
                            property: 'article:author',
                            content: author,
                        });
                    });
                }

                if (props.openGraph.article.section) {
                    meta.push({
                        property: 'article:section',
                        content: props.openGraph.article.section,
                    });
                }

                if (props.openGraph.article.tags?.length) {
                    props.openGraph.article.tags.forEach((tag) => {
                        meta.push({
                            property: 'article:tag',
                            content: tag,
                        });
                    });
                }
            } else if (
                (type === 'video.movie' ||
                    type === 'video.episode' ||
                    type === 'video.tv_show' ||
                    type === 'video.other') &&
                props.openGraph.video
            ) {
                if (props.openGraph.video.actors?.length) {
                    props.openGraph.video.actors.forEach((actor) => {
                        if (actor.profile) {
                            meta.push({
                                property: 'video:actor',
                                content: actor.profile,
                            });
                        }

                        if (actor.role) {
                            meta.push({
                                property: 'video:actor:role',
                                content: actor.role,
                            });
                        }
                    });
                }

                if (props.openGraph.video.directors?.length) {
                    props.openGraph.video.directors.forEach((director) => {
                        meta.push({
                            property: 'video:director',
                            content: director,
                        });
                    });
                }

                if (props.openGraph.video.writers?.length) {
                    props.openGraph.video.writers.forEach((writer) => {
                        meta.push({
                            property: 'video:writer',
                            content: writer,
                        });
                    });
                }

                if (props.openGraph.video.duration) {
                    meta.push({
                        property: 'video:duration',
                        content: props.openGraph.video.duration.toString(),
                    });
                }

                if (props.openGraph.video.releaseDate) {
                    meta.push({
                        property: 'video:release_date',
                        content: props.openGraph.video.releaseDate,
                    });
                }

                if (props.openGraph.video.tags?.length) {
                    props.openGraph.video.tags.forEach((tag) => {
                        meta.push({
                            property: 'video:tag',
                            content: tag,
                        });
                    });
                }

                if (props.openGraph.video.series) {
                    meta.push({
                        property: 'video:series',
                        content: props.openGraph.video.series,
                    });
                }
            }
        }

        if (props.openGraph.title || props.title) {
            meta.push({
                property: 'og:title',
                content: props.openGraph.title ?? (props.titleTemplate ?? '').replace('%s', props.title ?? ''),
            });
        }

        if (props.openGraph.description || props.description) {
            meta.push({
                property: 'og:description',
                content: props.openGraph.description ?? props.description,
            });
        }

        // images
        if (props.defaultOpenGraphImageWidth) {
            DEFAULTS.defaultOpenGraphImageWidth = props.defaultOpenGraphImageWidth;
        }

        if (props.defaultOpenGraphImageHeight) {
            DEFAULTS.defaultOpenGraphImageHeight = props.defaultOpenGraphImageHeight;
        }

        if (props.openGraph.images?.length) {
            props.openGraph.images.forEach((image) => {
                meta.push({
                    property: 'og:image',
                    content: image.url,
                });

                if (image.alt) {
                    meta.push({
                        property: 'og:image:alt',
                        content: image.alt,
                    });
                }

                if (image.width) {
                    meta.push({
                        property: 'og:image:width',
                        content: image.width.toString(),
                    });
                } else if (DEFAULTS.defaultOpenGraphImageWidth) {
                    meta.push({
                        property: 'og:image:width',
                        content: DEFAULTS.defaultOpenGraphImageWidth.toString(),
                    });
                }

                if (image.height) {
                    meta.push({
                        property: 'og:image:height',
                        content: image.height.toString(),
                    });
                } else if (DEFAULTS.defaultOpenGraphImageHeight) {
                    meta.push({
                        property: 'og:image:height',
                        content: DEFAULTS.defaultOpenGraphImageHeight.toString(),
                    });
                }
            });
        }

        // videos
        if (props.defaultOpenGraphVideoWidth) {
            DEFAULTS.defaultOpenGraphVideoWidth = props.defaultOpenGraphVideoWidth;
        }

        if (props.defaultOpenGraphVideoHeight) {
            DEFAULTS.defaultOpenGraphVideoHeight = props.defaultOpenGraphVideoHeight;
        }

        if (props.openGraph.videos?.length) {
            props.openGraph.videos.forEach((video) => {
                meta.push({
                    property: 'og:video',
                    content: video.url,
                });

                if (video.alt) {
                    meta.push({
                        property: 'og:video:alt',
                        content: video.alt,
                    });
                }

                if (video.width) {
                    meta.push({
                        property: 'og:video:width',
                        content: video.width.toString(),
                    });
                } else if (DEFAULTS.defaultOpenGraphVideoWidth) {
                    meta.push({
                        property: 'og:video:width',
                        content: DEFAULTS.defaultOpenGraphVideoWidth.toString(),
                    });
                }

                if (video.height) {
                    meta.push({
                        property: 'og:video:height',
                        content: video.height.toString(),
                    });
                } else if (DEFAULTS.defaultOpenGraphVideoHeight) {
                    meta.push({
                        property: 'og:video:height',
                        content: DEFAULTS.defaultOpenGraphVideoHeight.toString(),
                    });
                }
            });
        }

        if (props.openGraph.locale) {
            meta.push({ property: 'og:locale', content: props.openGraph.locale });
        }

        if (props.openGraph.site_name) {
            meta.push({
                property: 'og:site_name',
                content: props.openGraph.site_name,
            });
        }
    }

    if (props.canonical) {
        link.push({ rel: 'canonical', href: props.canonical, key: 'canonical' });
    }

    if (props.blogCatalog) {
        meta.push({ name: 'blogcatalog' });
    }

    if (props.metaTags) {
        props.metaTags.forEach((tag) => {
            meta.push(tag);
        });
    }

    if (props.linkTags) {
        props.linkTags.forEach((tag) => {
            link.push(tag);
        });
    }

    const metaElems = [];
    const linkElems = [];

    if (props.metaTags) {
        props.metaTags.forEach((tag, index) => {
            meta.push(tag);
        });
    }

    if (props.linkTags) {
        props.linkTags.forEach((tag, index) => {
            link.push(tag);
        });
    }

    meta.forEach((tag, index) => {
        metaElems.push(MetaTagElement(tag, index));
    });

    link.forEach((tag, index) => {
        linkElems.push(LinkTagElement(tag, index));
    });

    const htmlAttributes = props.language
        ? { lang: props.language, ...props.htmlAttributes }
        : { ...props.htmlAttributes };

    const helmetProps: HelmetProps = {
        defer: props.defer,
        htmlAttributes,
        logoProps: props.logoProps,
        blogProps: props.blogProps,
        projectProps: props.projectProps,
        localBusinessProps: props.localBusinessProps,
    };

    if (props.title) {
        helmetProps['title'] = props.title;
    }

    if (props.titleTemplate) {
        helmetProps['titleTemplate'] = props.titleTemplate;
    }

    return (
        <>
            {/* <title>{titleText}</title> */}
            {/* <link rel="shortcut icon" href="/icons/icon-72x72.png" /> */}
            {linkElems}
            {metaElems}
        </>
    );
};

export function JsonComponents(props): React.ReactElement {
    return (
        <>
            {props.logoProps && <LogoJsonLDComponent {...props.logoProps} />}
            {props.blogProps && <BlogJsonLDComponent {...props.blogProps} />}
            {props.projectProps && <ProjectJsonLDComponent {...props.projectProps} />}
            {props.localBusinessProps && <LocalBusinessJsonLDComponent {...props.localBusinessProps} />}
        </>
    );
}

export default SEO;
