import { graphql, useStaticQuery } from 'gatsby';

import { ImageResponse } from '../../types';

interface AboutSectionQueryResult {
    allAboutMarkdown: {
        sections: {
            html: string;
        }[];
    };
    aboutImage: ImageResponse;
}

export const useLocalDataSource = (): AboutSectionQueryResult => {
    return useStaticQuery(graphql`
        query AboutSectionQuery {
            allAboutMarkdown: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/sections/about/" } }) {
                sections: nodes {
                    html
                }
            }
            aboutImage: contentfulImage(name: { eq: "commercial-lapeer-exterior-1" }) {
                data {
                    gatsbyImageData(layout: CONSTRAINED, width: 320, quality: 50, placeholder: TRACED_SVG)
                }
                alt
                name
            }
        }
    `);
};
