/* eslint-disable no-unused-vars */
import React from "react";
import SEO, { LocalBusinessJsonLDComponent, LogoJsonLDComponent } from "../Seo";
import { Script } from "gatsby";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

export const TermlyScript = () => {
  return (
    <>
      <Script
        strategy="post-hydrate"
        type="text/javascript"
        src="https://app.termly.io/resource-blocker/f41afe8a-7c07-44e9-a0ed-c56380566ea3?autoBlock=on"
        id="termly-script"
        key="termly-script"
      />
    </>
  );
};

export const AdSenseScript = () => {
  return (
    <Script
      src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${process.env.GATSBY_GOOGLE_ADSENSE_KEY}`}
      id="adsense-external"
      key="adsense-external"
      strategy="post-hydrate"
      crossOrigin="anonymous"
      async={true}
      defer={true}
    />
  );
};

// export const GTMScript = () => {
//   if (process.env.NODE_ENV === "production") {
//     return (
//       <>
//         <Script
//           async
//           strategy="off-main-thread"
//           src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_TAG_ID}`}
//           forward={[`dataLayer.push`]}
//           id="GTM-script"
//           key="GTM-script"
//         />
//         <Script
//           id="GTM-dataLayer"
//           key="GTM-dataLayer"
//           strategy="off-main-thread"
//         >{`
//                 window.dataLayer = window.dataLayer || [];
//                 function gtag(){dataLayer.push(arguments);}
//                 gtag('js', ${new Date()});
//
//                 gtag('config', '${process.env.GATSBY_GOOGLE_TAG_ID}');
//                 `}
//         </Script>
//       </>
//     );
//   } else {
//     return undefined;
//   }
// };

//
// export const ClarityScript = () => {
//   if (process.env.NODE_ENV === "production"
//   ) {
//     return (
//       <Script id="ms-clarity"
//               type="text/javascript"
//               strategy="off-main-thread"
//               forward={["window.clarity"]}
//               async={true}
//               defer={false}
//       >{`
//                 (function (c, l, a, r, i, t, y) {
//                 c[a] = c[a] || function () {
//                     (c[a].q = c[a].q || []).push(arguments)
//                 };
//                 t = l.createElement(r);
//                 t.async = 1;
//                 t.src = "https://www.clarity.ms/tag/" + i;
//                 y = l.getElementsByTagName(r)[0];
//                 y.parentNode.insertBefore(t, y);
//                 })
//             (window, document, "clarity", "script", "${process.env.GATSBY_MS_CLARITY_PROJECT_ID}");
//             `}</Script>
//     );
//   } else {
//     return undefined;
//   }
// };

const HeadCommon = ({ title }: { title?: string }): React.ReactElement => {
  const siteMetadata = useSiteMetadata();

  const seoProps = {
    lang: `en`,
    defaultOpenGraphImageWidth: 1200,
    localBusinessProps: {
      ...siteMetadata.localBusiness,
      openingHours: undefined,
      rating: undefined,
      priceRange: undefined,
      overrides: undefined
    },
    description: siteMetadata.description,
    titleTemplate: siteMetadata.titleTemplate,
    canonical: siteMetadata.siteUrl,
    logoProps: { logo: siteMetadata.logoUrl, url: siteMetadata.siteUrl },
    title: title ?? siteMetadata.title,
    htmlAttributes: {
      language: `en`
    },
    twitter: {
      cardType: `summary_large_image`,
      site: `@baldwin_group`,
      handle: `@baldwin_group`
    },
    metaTags: [
      {
        property: "description",
        content: siteMetadata.description
      },
      {
        property: "title",
        content: siteMetadata.title
      },
      {
        property: "dc:creator",
        content: "Codality Ltd."
      }
    ],
    openGraph: {
      type: "website",
      url: siteMetadata.siteUrl,
      title: siteMetadata.title,
      titleTemplate: siteMetadata.titleTemplate,
      site_name: siteMetadata.title,
      description: siteMetadata.description,
      images: [
        {
          url: `https://www.baldwinarchitect.com/logo.png`,
          width: 400,
          height: 110,
          alt: `Baldwin Architectural Group logo`
        },
        {
          url: `https://www.baldwinarchitect.com/icon.png`,
          width: 512,
          height: 512,
          alt: `Baldwin Architectural Group icon`
        },
        {
          url: `https://www.baldwinarchitect.com/commercial-1.png`,
          width: 750,
          height: 900,
          alt: `Baldwin Architectural Group Commercial Services`
        },
        {
          url: `https://www.baldwinarchitect.com/milltown-rendering.png`,
          width: 900,
          height: 600,
          alt: `Baldwin Architectural Group Community Planning Services`
        },
        {
          url: `https://www.baldwinarchitect.com/house-1.png`,
          width: 900,
          height: 400,
          alt: `Baldwin Architectural Group Residential Services`
        }
      ]
    }
  };

  return (
    <>
      <TermlyScript />
      <AdSenseScript />
      <LogoJsonLDComponent {...seoProps.logoProps} />
      <LocalBusinessJsonLDComponent {...seoProps.localBusinessProps} />
      <SEO {...seoProps} />
      {/*<GTMScript />*/}
      {/*<ClarityScript />*/}
    </>
  );
};

export default HeadCommon;
