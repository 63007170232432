/* eslint-disable no-unused-vars */
import React, {lazy} from 'react';
import {SocialProfiles} from '../../components/SocialProfiles';
import {HeroSectionJson, ImageResponse, PageSection, SourceProps} from '../../types';
import heroDataSource from './data';
import * as classes from './style.module.css';
import Section from '../../components/Section';
import Animation from '../../components/Animation';

const ReactBackgroundSlideshow = lazy(() => import('../../components/BackgroundSlideshow'));

export default function HeroSection(props: PageSection): React.ReactElement {
    const response: HeroSectionJson = heroDataSource();
    const data = response.allHeroJson.heroContent[0];
    const images: (() => React.ReactElement)[] = response.heroSlideshow.images.flatMap((image: ImageResponse) =>
            () => (
                <img
                    alt={image.alt}
                    srcSet={image.data.gatsbyImageData.images.sources
                        .reduce((
                            previousValue: string,
                            currentValue: SourceProps,
                            index: number,
                            sources: SourceProps[]): string => {
                                if (index == 0) {
                                    return sources[index].srcSet;
                                } else {
                                    return previousValue.concat(",\n", sources[index].srcSet)
                                }
                            }, ""
                        )
                    }
                    className={classes.SlideshowImage}
                />
            )
    )
    return (
        <>
            <Section anchor={props.sectionId} additionalClass={classes.Hero}>
                <Animation type="fadeUp" delay={400}>
                    <div className={classes.Intro}>
                        <h1 className={classes.Title}>
                            {data.intro && <span className={classes.ImagePrefix}>{data.intro}</span>}
                        </h1>
                        <Animation
                            className={classes.WelcomeImage}
                            type="waving-hand"
                            duration={2500}
                            iterationCount={3}
                        >
                            <img src={'/icon.png'} alt="Baldwin Architectural Group Icon" width={48} height={48}/>
                        </Animation>
                    </div>
                    <h1 className={classes.Title}>{data.title}</h1>
                    <h2 className={classes.Subtitle}>
                        {data.subtitle.prefix}
                        <u>{data.subtitle.highlight}</u>
                        {data.subtitle.suffix}
                    </h2>
                    <p>{data.description}</p>
                </Animation>
                <Animation className={classes.SocialLinks} type="fadeLeft" delay={800}>
                    {data.socialProfiles && <SocialProfiles profiles={data.socialProfiles}/>}
                </Animation>
            </Section>
            <ReactBackgroundSlideshow images={images}/>
        </>
    );
}
