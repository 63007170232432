// extracted by mini-css-extract-plugin
export var BackArrow = "style-module--BackArrow--b12e4";
export var Banner = "style-module--Banner--1b161";
export var BannerCaption = "style-module--BannerCaption--4b16e";
export var BannerImage = "style-module--BannerImage--745c8";
export var Body = "style-module--Body--a93c0";
export var Breadcrumb = "style-module--Breadcrumb--f8f85";
export var Category = "style-module--Category--dbe87";
export var Content = "style-module--Content--cb751";
export var Details = "style-module--Details--c359b";
export var Footer = "style-module--Footer--fccdd";
export var Header = "style-module--Header--ea8fe";
export var Keyword = "style-module--Keyword--32170";
export var ReadingTime = "style-module--ReadingTime--1c6ff";
export var Service = "style-module--Service--d03de";
export var Slideshow = "style-module--Slideshow--e8a28";