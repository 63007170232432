import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Section from '../../components/Section';
import { SocialProfiles } from '../../components/SocialProfiles';
import useLocalDataSource from './data';
import { PageSection } from '../../types';
import * as classes from './style.module.css';
import { CONTACT_URL } from '../../context/constants';
import { LinkHandler, LinkType } from '../../components/LinkHandler';
import Animation from '../../components/Animation';

export default function ContactSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.contentfulAuthor;
    const heroAvatar = data.avatar;

    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading} additionalClass={classes.Contact}>
                <p className={classes.Description}>Talk to an Architect today!</p>
                <div className={classes.Profile}>
                    {heroAvatar && (
                        // @ts-ignore
                        <LinkHandler href={CONTACT_URL} title="Contact Us" externalLink={false} type={LinkType.LINK}>
                            <GatsbyImage
                                className={classes.Avatar}
                                image={heroAvatar.data.gatsbyImageData}
                                alt={heroAvatar.alt ? heroAvatar.alt : heroAvatar.name}
                            />
                        </LinkHandler>
                    )}
                    <div className={classes.ContactDetails}>
                        <div className={classes.Name}>{data.name}</div>
                        <u>
                            <LinkHandler type={LinkType.LINK} externalLink={true} href={`mailto:${data.email}`}>
                                {data.email}
                            </LinkHandler>
                        </u>
                    </div>
                </div>
                {data.socialProfiles && <SocialProfiles profiles={data.socialProfiles} />}
            </Section>
        </Animation>
    );
}
