import React from 'react';
import Section from '../../components/Section';
import { PageSection, ProjectData } from '../../types';
import * as classes from './style.module.css';
import { ProjectSkeleton } from '../../components/ProjectCard';
import Slider from '../../components/Slider';
import ProjectCard from '../../components/ProjectCard';
import { useLocalDataSource } from './data';
import Animation from '../../components/Animation';
import useEffectOnce from '../../hooks/useEffectOnce';

export default function ProjectsSection(props: PageSection): React.ReactElement {
    const { allContentfulArticle, allSitePage } = useLocalDataSource();
    const [projectCards, setProjects] = React.useState<ProjectData[]>([]);
    async function collectProjectsFromSources(): Promise<ProjectData[]> {
        const projectList: ProjectData[] = [];
        const { projects } = allContentfulArticle;
        const { projectMetadata } = allSitePage;
        if (projects.length > 0) {
            projects.forEach(({ project }): void => {
                const metadata = projectMetadata.find((meta) => meta.metadata.path.includes(project.slug));
                projectList.push({
                    ...project,
                    link: '/project'.concat('/').concat(project.slug),
                    readTime: metadata.metadata.pageContext.readTime,
                    slug: project.slug,
                });
            });
        }
        return projectList
            .slice()
            .sort((a, b) => new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime());
    }

    useEffectOnce(() => {
        (async function () {
            setProjects(await collectProjectsFromSources());
        })();
    });

    return (
        <Animation type="fadeUp" delay={300}>
            <Section anchor={props.sectionId} heading={props.heading}>
                <Slider additionalClasses={[classes.Projects]}>
                    {projectCards.length > 0
                        ? projectCards.slice(0, 5).map((project, key) => {
                              return <ProjectCard key={key} showBanner={true} data={project} />;
                          })
                        : [...Array(5)].map((_, key) => {
                              return <ProjectSkeleton key={key} />;
                          })}
                </Slider>
            </Section>
        </Animation>
    );
}
