/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-labels */
import React, { Suspense } from 'react';
import Page from '../../components/Page';
import Section from '../../components/Section';
import { LinkHandler, LinkType } from '../../components/LinkHandler';
import * as classes from './style.module.css';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { ProjectData, ProjectTemplateProps } from '../../types';
import ProjectCard from '../../components/ProjectCard';
import Slider from '../../components/Slider';
import { AllSeoProps } from '../../components/Seo/helpers';
import SEO from '../../components/Seo';
import { SplashScreen } from '../../components/SplashScreen';

interface FilterOption {
    label: string;
    selected: boolean;
    relatedProjectIds: string[];
}

export default function ProjectListingTemplate(props: ProjectTemplateProps): React.ReactElement {
    const ARTICLES_PER_PAGE = 9;
    const projects = props.pageContext.projects;
    const [filterOptions, setFilterOptions] = React.useState<FilterOption[]>(extractFilterOptions(projects));
    const [shownProjectsNumber, setShownProjectsNumber] = React.useState<number>(ARTICLES_PER_PAGE);

    function handleFilterOptionClick(optionLabel: string): void {
        const updatedFilterOptions = [...filterOptions];
        const selectedOptionIndex = updatedFilterOptions.map((o) => o.label).indexOf(optionLabel);
        updatedFilterOptions[selectedOptionIndex].selected = !updatedFilterOptions[selectedOptionIndex].selected;
        setFilterOptions(updatedFilterOptions);
    }

    function handleLoadMoreButtonClick(projectsNumber: number, selectedProjectsNumber?: number): void {
        const incrementedProjectNumber = shownProjectsNumber + 3;
        if (selectedProjectsNumber && selectedProjectsNumber >= incrementedProjectNumber) {
            setShownProjectsNumber(incrementedProjectNumber);
        } else if (!selectedProjectsNumber && projectsNumber >= incrementedProjectNumber) {
            setShownProjectsNumber(incrementedProjectNumber);
        }
    }

    // Check if at least one filter option is selected. If so, create an array of all project ids that
    // are selected based on the current filter option selection. We use this later on to easily check
    // which projects to show.
    let selectedProjectIds: string[] = [];
    const filterSelected = filterOptions.map((o) => o.selected).indexOf(true) !== -1;
    if (filterSelected) {
        selectedProjectIds = filterOptions
            .filter((option) => option.selected) // Filter only for selected options
            .map((option) => option.relatedProjectIds) // Create an array of project ids arrays
            .flat(1) // Flatten the array to a string[]
            .filter((id, index, arr) => arr.indexOf(id) === index); // Remove duplicate project ids
    }

    return (
        <Suspense
            fallback={
                <div>
                    <SplashScreen />
                </div>
            }
        >
            <Page>
                <Section anchor="projectListing" heading="Projects">
                    <div className={classes.Filter}>
                        Select categories to filter projects
                        <Slider additionalClasses={[classes.Options]}>
                            {filterOptions.map((option, key) => {
                                return (
                                    <div
                                        key={key}
                                        role="button"
                                        onClick={() => handleFilterOptionClick(option.label)}
                                        className={[
                                            classes.Option,
                                            option.selected === true ? classes.Selected : null,
                                        ].join(' ')}
                                    >
                                        {option.label} ({option.relatedProjectIds.length})
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div className={classes.Listing}>
                        {projects
                            .filter((project) => !filterSelected || selectedProjectIds.includes(project.id))
                            .slice(0, shownProjectsNumber)
                            .map((project, key) => {
                                return (
                                    <div key={key}>
                                        <ProjectCard
                                            showBanner={true}
                                            data={{
                                                ...project,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                    {(filterSelected && selectedProjectIds.length > shownProjectsNumber) ||
                    (!filterSelected && projects.length > shownProjectsNumber) ? (
                        <div className={classes.LoadMore}>
                            <LinkHandler
                                type={LinkType.BUTTON}
                                label="Load More"
                                onClickHandler={() =>
                                    handleLoadMoreButtonClick(
                                        projects.length,
                                        filterSelected ? selectedProjectIds.length : undefined,
                                    )
                                }
                            />
                        </div>
                    ) : null}
                </Section>
            </Page>
        </Suspense>
    );
}

// Helper function to calculate a sorted array of filter options based on the given projects
// We use the helper function before we initialize the state so that it can happen on the server.
function extractFilterOptions(projects: ProjectData[]): FilterOption[] {
    const filterOptions: FilterOption[] = [];
    const categoryList: string[] = [];
    projects.forEach((project) => {
        project.categories.forEach((category) => {
            if (!categoryList.includes(category)) {
                filterOptions.push({
                    label: category,
                    selected: false,
                    relatedProjectIds: [project.id],
                });
                categoryList.push(category);
            } else {
                const optionIndex = filterOptions.map((o) => o.label).indexOf(category);
                filterOptions[optionIndex].relatedProjectIds.push(project.id);
            }
        });
    });
    return filterOptions.sort((a, b) => (a.relatedProjectIds.length > b.relatedProjectIds.length ? -1 : 1));
}

export const Head = (props: ProjectTemplateProps) => {
    const siteMetadata = useSiteMetadata();
    const projects = props.pageContext.projects;
    const pageTitle = 'Projects';
    const desc = 'Inside information on architectural design, how-tos and insights into sustainable practices.';
    const canonicalPath = '/project';
    const openGraphImageProps = projects.map((project) => {
        return {
            url: project.banner.data.publicUrl,
            width: project.banner.data.gatsbyImageData.width,
            height: project.banner.data.gatsbyImageData.height,
            alt: project.banner.alt,
        };
    });
    const siteUrl = siteMetadata.siteUrl.concat(canonicalPath);

    const seoProps: AllSeoProps = {
        title: pageTitle,
        description: desc,
        titleTemplate: siteMetadata.titleTemplate,
        openGraph: {
            type: 'website',
            url: siteUrl,
            title: pageTitle,
            description: desc,
            images: openGraphImageProps,
        },
        blogProps: {
            url: siteUrl,
            headline: desc,
            images: projects.map((project) => {
                return project.banner.data.publicUrl;
            }),
            posts: projects.map((project) => {
                return {
                    headline: project.title,
                    image: project.banner.data.publicUrl,
                };
            }),
            datePublished: projects[0].datePublished,
            dateModified: projects[0].datePublished,
        },
    };
    return (
        <>
            <SEO {...seoProps} />
        </>
    );
};
