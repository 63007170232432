import React from 'react';
import { IconExternal } from './IconExternal';
import IconFacebook from './IconFacebook';
import { IconLinkedIn } from './IconLinkedIn';
import { IconMail } from './IconMail';
import { IconTwitter } from './IconTwitter';

interface IconProps {
    name: string;
    color?: string;
    className?: string;
    height?: number;
    width?: number;
}

export function Icon(props: IconProps): React.ReactElement | null {
    switch (props.name.toLowerCase()) {
        case 'external':
            return <IconExternal color={props.color} />;
        case 'linkedin':
            return <IconLinkedIn color={props.color} />;
        case 'mail':
            return <IconMail color={props.color} />;
        case 'facebook':
            return <IconFacebook color={props.color} height={props.height} width={props.width} />;
        case 'twitter':
            return <IconTwitter color={props.color} height={props.height} width={props.width} />;
        default:
            return null;
    }
}
