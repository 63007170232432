/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
    LocalBusiness as SchemaLocalBusiness,
    Blog,
    Organization,
    Thing,
    Article,
    OpeningHoursSpecification,
    AggregateRating,
} from 'schema-dts';
import { LiteralUnion } from 'type-fest';

export const BASE_DEFAULTS = {
    noindex: false,
    nofollow: false,
    defaultOpenGraphImageWidth: 0,
    defaultOpenGraphImageHeight: 0,
    defaultOpenGraphVideoWidth: 0,
    defaultOpenGraphVideoHeight: 0,
};

export interface JsonLdComponentProps {
    logoProps?: LogoJsonLdProps;
    blogProps?: BlogJsonLdProps;
    projectProps?: ArticleJsonLdProps;
    localBusinessProps?: LocalBusinessJsonLdProps;
}

/**
 * @public
 */
export interface OpenGraphImages {
    url: string;
    width?: number;
    height?: number;
    alt?: string;
}

/**
 * @public
 */
export interface OpenGraphVideos {
    url: string;
    width?: number;
    height?: number;
    alt?: string;
}

/**
 * @public
 */
export interface OpenGraphVideoActors {
    profile: string;
    role?: string;
}

/**
 * @public
 */
export interface OpenGraph {
    /**
     * The canonical URL of your object that will be used as its permanent ID in
     * the graph.
     */
    url?: string;

    /**
     * The type of your object. Depending on the type you specify, other
     * properties may also be required.
     */
    type?: string;

    /**
     * The open graph title, this can be different than your meta title.
     */
    title?: string;

    /**
     * The open graph description, this can be different than your meta
     * description.
     */
    description?: string;

    /**
     * An array of images (object) to be used by social media platforms, slack etc
     * as a preview. If multiple supplied you can choose one when sharing.
     */
    images?: OpenGraphImages[];

    /**
     * An array of videos.
     */
    videos?: OpenGraphVideos[];

    /**
     * The default height for the image used.
     */
    defaultImageHeight?: number;

    /**
     * The default width of the image used.
     */
    defaultImageWidth?: number;

    /**
     * The locale the open graph tags are marked up in. Of the format
     * language_TERRITORY.
     *
     * @defaultValue 'en_US'
     */
    locale?: string;

    /**
     * If your object is part of a larger web site, the name which should be
     * displayed for the overall site.
     */
    site_name?: string;

    /**
     * The open graph profile configuration object.
     */
    profile?: OpenGraphProfile;

    /**
     * The open graph book configuration object.
     */
    book?: OpenGraphBook;

    /**
     * The open graph article configuration object.
     */
    article?: OpenGraphArticle;

    /**
     * The open graph video configuration object.
     */
    video?: OpenGraphVideo;
}

/**
 * @public
 */
export interface OpenGraphProfile {
    /**
     * Person's first name.
     */
    firstName?: string;

    /**
     * Person's last name.
     */
    lastName?: string;

    /**
     * Person's username.
     */
    username?: string;

    /**
     * Person's gender.
     */
    gender?: string;
}

/**
 * @public
 */
export interface OpenGraphBook {
    /**
     * The list of author names for the book.
     */
    authors?: string[];

    /**
     * The International Standard Book Number which identifies the book.
     *
     * @remarks
     *
     * An ISBN is essentially a product identifier used by publishers, booksellers, libraries, internet retailers and other supply chain participants for ordering, listing, sales records and stock control purposes. The ISBN identifies the registrant as well as the specific title, edition and format.
     */
    isbn?: string;

    /**
     * The books release date.
     */
    releaseDate?: string;

    /**
     * Tags used to further describe the book.
     */
    tags?: string[];
}

/**
 * @public
 */
export interface OpenGraphArticle {
    publishedTime?: string;
    modifiedTime?: string;
    expirationTime?: string;
    authors?: string[];
    section?: string;
    tags?: string[];
}

/**
 * @public
 */
export interface OpenGraphVideo {
    actors?: OpenGraphVideoActors[];
    directors?: string[];
    writers?: string[];
    duration?: number;
    releaseDate?: string;
    tags?: string[];
    series?: string;
}

export type TwitterCardType = 'summary' | 'summary_large_image' | 'app' | 'player';

/**
 * @public
 */
export interface Twitter {
    /**
     * `@username` for the content creator / author (outputs as `twitter:creator`).
     */
    handle?: string;

    /**GatsbySeoProps
     * `@username` for the website used in the card footer.
     */
    site?: string;

    /**
     * The card type, which will be one of `summary`, `summary_large_image`,
     * `app`, or `player`.
     */
    cardType?: LiteralUnion<TwitterCardType, string>;
}

interface MobileAlternate {
    /**
     * Set what screen size the mobile website should be served from.
     */
    media: string;

    /**
     * Set the mobile page alternate url.
     */
    href: string;
}

interface LanguageAlternate {
    hrefLang: string;
    href: string;
}

interface HTMLAttributes {
    language?: string;
    prefix?: string;
}

/**
 * @public
 */
export interface BaseSeoProps {
    blogCatalog?: boolean;
    /**
     * The language being used for the current page.
     *
     * This adds the `lang` attribute to the  `<html />` e.g. tag https://web.dev/html-has-lang/.
     */
    language?: string;

    /**
     * Set the meta title of the page
     */
    title?: string;

    /**
     * Allows you to set default title template that will be added to your title.
     *
     * @remarks
     *
     * Replaces `%s` with your title string
     *
     * ```js
     * title = 'This is my title';
     * titleTemplate = 'Gatsby SEO | %s';
     * // outputs: Gatsby SEO | This is my title
     * ```
     *
     * ```js
     * title = 'This is my title';
     * titleTemplate = '%s | Gatsby SEO';
     * // outputs: This is my title | Gatsby SEO
     * ```
     */
    titleTemplate?: string;

    /**
     * Sets whether page should be indexed or not.
     *
     * @remarks
     *
     * Setting this to `true` will set `noindex,follow` (to set `nofollow`, please
     * refer to [`nofollow`](#noFollow)). This works on a page by page basis. This
     * property works in tandem with the `nofollow` property and together they
     * populate the `robots` and `googlebot` meta tags. **Note:** The `noindex`
     * and the [`nofollow`](#noFollow) properties are a little different than all
     * the others in the sense that setting them as a default does not work as
     * expected. This is due to the fact Gatsby SEO already has a default of
     * `index,follow` because `gatsby-plugin-next-seo` is a SEO plugin after all.
     * So if you want to globally these properties, please see
     * [dangerouslySetAllPagesToNoIndex](#dangerouslySetAllPagesToNoIndex) and
     * [dangerouslySetAllPagesToNoFollow](#dangerouslySetAllPagesToNoFollow).
     *
     * **Example No Index on a single page:**
     *
     * If you have a single page that you want no indexed you can achieve this by:
     *
     * ```tsx
     * import React from 'react';
     * import { GatsbySeo } from 'gatsby-plugin-next-seo';
     *
     * export default () => (
     *   <>
     *     <GatsbySeo noindex={true} />
     *     <p>This page is no indexed</p>
     *   </>
     * );
     * ```
     */
    noindex?: boolean;

    /**
     * Sets whether page should be followed or not.
     *
     * @remarks
     *
     * Setting this to `true` will set `index,nofollow` (to set `noindex`, please
     * refer to [`noindex`](#noIndex)). This works on a page by page basis. This
     * property works in tandem with the `noindex` property and together they
     * populate the `robots` and `googlebot` meta tags.
     *
     * **Note:** The `noindex` and the [`nofollow`](#noFollow) properties are a
     * little different than all the others in the sense that setting them as a
     * default does not work as expected. This is due to the fact Gatsby SEO
     * already has a default of `index,follow` because `gatsby-plugin-next-seo` is
     * a SEO plugin after all. So if you want to globally these properties, please
     * see [dangerouslySetAllPagesToNoIndex](#dangerouslySetAllPagesToNoIndex) and
     * [dangerouslySetAllPagesToNoFollow](#dangerouslySetAllPagesToNoFollow).
     *
     * **Example No Follow on a single page:**
     *
     * If you have a single page that you want no indexed you can achieve this by:
     *
     * ```jsx
     * import React from 'react';
     * import { GatsbySeo } from 'gatsby-plugin-next-seo';
     *
     * export default () => (
     *   <>
     *     <GatsbySeo nofollow={true} />
     *     <p>This page is not followed</p>
     *   </>
     * );
     * ```
     */
    nofollow?: boolean;

    /**
     * Set the page meta description.
     */
    description?: string;

    /**
     * Set the page canonical url.
     *
     * @remarks
     *
     * Add this on a page per page basis when you want to consolidate duplicate
     * URLs.
     *
     * ```js
     * canonical = 'https://www.canonical.ie/';
     * ```
     */
    canonical?: string;

    /**
     * Mobile configuration object.
     *
     * @remarks
     *
     * This link relation is used to indicate a relation between a desktop and a
     * mobile website to search engines.
     *
     * Example:
     *
     * ```jsx
     * mobileAlternate={{
     *   media: 'only screen and (max-width: 640px)',
     *   href: 'https://m.canonical.ie',
     * }}
     * ```
     *
     * ```jsx
     * languageAlternate={{
     *   hrefLang: 'de-AT',
     *   href: 'https://www.canonical.ie/de',
     * }}
     * ```
     */
    mobileAlternate?: MobileAlternate;

    /**
     * Set the language of the alternate urls.
     */
    languageAlternates?: LanguageAlternate[];

    /**
     * The open graph configuration object.
     */
    openGraph?: OpenGraph;

    /**
     * Used for Facebook Insights, you must add a facebook app ID to your page to
     * for it.
     *
     * @remarks
     *
     * ```tsx
     * facebook={{
     *   appId: 1234567890,
     * }}
     * ```
     *
     */
    facebook?: { appId: string };

    /**
     * The twitter configuration object.
     *
     * @remarks
     *
     * Twitter will read the `og:title`, `og:image` and `og:description` tags for
     * their card, this is why `gatsby-plugin-next-seo` omits `twitter:title`,
     * `twitter:image` and `twitter:description` so not to duplicate.
     *
     * Some tools may report this an error. See [Issue
     * #14](https://github.com/garmeeh/gatsby-plugin-next-seo/issues/14)
     */
    twitter?: Twitter;

    /**
     * Allows you to add a meta tag that is not documented here.
     *
     * @remarks
     *
     * This allows you to add any other meta tags that are not covered in the
     * `config`.
     *
     * `content` is required. Then either `name` or `property`. (Only one on each)
     *
     * Example:
     *
     * ```js
     * metaTags={[{
     *   property: 'dc:creator',
     *   content: 'Jane Doe'
     * }, {
     *   name: 'application-name',
     *   content: 'GatsbySeo'
     * }]}
     * ```
     *
     * Invalid Examples:
     *
     * These are invalid as they contain `property` and `name` on the same entry.
     *
     * ```js
     * metaTags={[{
     *   property: 'dc:creator',
     *   name: 'dc:creator',
     *   content: 'Jane Doe'
     * }, {
     *   property: 'application-name',
     *   name: 'application-name',
     *   content: 'GatsbySeo'
     * }]}
     * ```
     */
    metaTags?: MetaProps[];

    /**
     * Allows you to add a link tag that is not documented here.
     */
    linkTags?: LinkProps[];

    /**
     * Allows you to add additional attributes to html tag besides language.
     */
    htmlAttributes?: HTMLAttributes;
}

/**
 * @public
 */
export interface DeferSeoProps {
    /**
     * Whether or not to defer the addition of the head tag.
     *
     * @defaultValue false
     */
    defer?: boolean;
}

/**
 * @public
 */
export interface GatsbySeoProps extends BaseSeoProps, DeferSeoProps {}

/**
 * @public
 */
export interface DefaultSeoProps {
    /**
     * It has the prefix of `dangerously` because it will `noindex` all pages. As
     * this is an SEO plugin, that is kinda dangerous action. It is **not** bad to
     * use this, just please be sure you want to `noindex` **EVERY** page. You can
     * still override this at a page level if you have a use case to `index` a
     * page. This can be done by setting `noindex: false`.
     */
    dangerouslySetAllPagesToNoIndex?: boolean;

    /**
     * It has the prefix of `dangerously` because it will `nofollow` all pages. As
     * this is an SEO plugin, that is kinda dangerous action. It is **not** bad to
     * use this, just please be sure you want to `nofollow` **EVERY** page. You
     * can still override this at a page level if you have a use case to `follow`
     * a page. This can be done by setting `nofollow: false`.
     */
    dangerouslySetAllPagesToNoFollow?: boolean;

    /**
     * The default open graph image width.
     */
    defaultOpenGraphImageWidth?: number;

    /**
     * The default open graph image height.
     */
    defaultOpenGraphImageHeight?: number;

    /**
     * The default open graph video width.
     */
    defaultOpenGraphVideoWidth?: number;

    /**
     * The default open graph video height.
     */
    defaultOpenGraphVideoHeight?: number;
}

/**
 * @public
 */
export interface AllSeoProps extends DefaultSeoProps, GatsbySeoProps, JsonLdComponentProps {
    children?: React.ReactElement;
    defer?: boolean;
    metaTags?: MetaProps[];
    linkTags?: LinkProps[];
}

/**
 * @public
 */
export interface GatsbySeoPluginOptions extends DefaultSeoProps, BaseSeoProps {}

/**
 * @internal
 */
export interface OtherElementAttributes {
    [key: string]: string | number | boolean | null | undefined;
}

/**
 * @internal
 */
export type HtmlProps = JSX.IntrinsicElements['html'] & OtherElementAttributes;
/**
 * @internal
 */
export type BodyProps = JSX.IntrinsicElements['body'] & OtherElementAttributes;
/**
s * @internal
 */
export type LinkProps = JSX.IntrinsicElements['link'];
/**
 * @internal
 */
export type MetaProps = JSX.IntrinsicElements['meta'];
/**
 * @internal
 */
export type NoscriptProps = JSX.IntrinsicElements['noscript'];
/**
 * @internal
 */
export type ScriptProps = JSX.IntrinsicElements['script'];
/**
 * @internal
 */
export type StyleProps = JSX.IntrinsicElements['style'];
/**
 * @internal
 */
export type TitleProps = HTMLTitleElement;

export interface HelmetProps extends JsonLdComponentProps {
    async?: boolean;
    base?: any;
    bodyAttributes?: BodyProps;
    defaultTitle?: string;
    defer?: boolean;
    encodeSpecialCharacters?: boolean;
    helmetData?: any;
    htmlAttributes?: HtmlProps;
    onChangeClientState?: (newState: any, addedTags: any, removedTags: any) => void;
    link?: LinkProps[];
    meta?: MetaProps[];
    noscript?: Array<any>;
    script?: Array<any>;
    style?: Array<any>;
    title?: string;
    titleAttributes?: Object;
    titleTemplate?: string;
    prioritizeSeoTags?: boolean;
}

export interface LogoJsonLdProps extends DeferSeoProps, Overrides<Extract<Organization, object>> {
    /**
     * URL of a logo that is representative of the organization.
     *
     * @remarks
     *
     * Additional image guidelines:
     *
     * - The image must be 112x112px, at minimum.
     * - The image URL must be crawlable and indexable.
     * - The image must be in .jpg, .png, or. gif format.
     */
    logo: string;
    /**
     * The URL of the website associated with the logo.
     */
    url: string;
}

export interface BlogJsonLdProps extends DeferSeoProps, Overrides<Blog> {
    /**
     * The canonical URL of the article page.
     */
    url: string;
    /**
     * The headline of the article.
     *
     * @remarks
     *
     * Headlines should not exceed 110 characters. For AMP stories, the headline
     * should match the text in the first cover page in the AMP Story.
     */
    headline: string | string[];
    /**
     * @deprecated
     *
     * Use headline instead.
     */
    title?: string;
    /**
     * Keywords or tags used to describe this content. Multiple entries in a
     * keywords list are typically delimited by commas.
     */
    keywords?: string[];
    /**
     * The images URLs that is representative of the article or AMP story.
     *
     * @remarks
     *
     * Due to format differences in search results, the following image guidelines
     * only apply to general AMP pages, not AMP stories. AMP stories have
     * {@link https://www.ampproject.org/docs/reference/components/amp-story#new-metadata-requirements | different requirements}
     * for images.
     *
     * - Only a marked-up image that directly belongs to the article should be
     *   specified.
     * - Images should be at least 1200 pixels wide.
     * - Every page must contain at least one image (whether or not you include
     *   markup). Google will pick the best image to display in Search results
     *   based on the aspect ratio and resolution.
     * - Image URLs must be crawlable and indexable.
     * - Images must represent the marked up content. Images must be in .jpg,
     *   .png, or .gif format.
     * - For best results, provide multiple high-resolution images (minimum of
     *   800,000 pixels when multiplying width and height) with the following
     *   aspect ratios: 16x9, 4x3, and 1x1.
     */
    images?: string[];
    /**
     * The date and time the article was first published, in ISO 8601 format.
     *
     * @remarks
     *
     * Best practices:
     *
     * - The date shouldn't change over time.
     * - We recommend including the hour information in addition to the day in the
     *   timestamp.
     * - The value for dateModified should be more recent than the value for
     *   datePublished.
     */
    datePublished?: string;
    /**
     * The date and time the article was most recently modified, in ISO 8601
     * format.
     */
    dateModified?: string;
    /**
     * The name of the author.
     */
    authorName?: string;
    /**
     * The type of author for this article.
     *
     * @defaultValue 'Person'
     */
    authorType?: 'Person' | 'Organization';
    /**
     * A short description of the article.
     */
    description?: string;
    /**
     * The name of the publisher.
     */
    publisherName?: string;
    /**
     * The url of the publisher logo.
     */
    publisherLogo?: string;
    /**
     * A posting that is part of this blog.
     */
    posts?: BlogPost[];
    /**
     * The International Standard Serial Number (ISSN) that identifies this serial
     * publication. You can repeat this property to identify different formats of,
     * or the linking ISSN (ISSN-L) for, this serial publication.
     */
    issn?: string | string[];
}

export interface ArticleJsonLdProps extends DeferSeoProps, Overrides<Article> {
    /**
     * The canonical URL of the article page.
     */
    url: string;
    /**
     * The headline of the article.
     *
     * @remarks
     *
     * Headlines should not exceed 110 characters. For AMP stories, the headline
     * should match the text in the first cover page in the AMP Story.
     */
    headline?: string | string[];
    /**
     * @deprecated
     *
     * Use headline instead.
     */
    title?: string;
    /**
     * Keywords or tags used to describe this content.
     *
     * @remarks
     *
     * Multiple entries in a keywords list are typically delimited by commas.
     */
    keywords?: string | string[];
    /**
     * The images URLs that is representative of the article or AMP story.
     *
     * @remarks
     *
     * Due to format differences in search results, the following image guidelines
     * only apply to general AMP pages, not AMP stories. AMP stories have
     * {@link https://www.ampproject.org/docs/reference/components/amp-story#new-metadata-requirements | different requirements}
     * for images.
     *
     * - Only a marked-up image that directly belongs to the article should be
     *   specified.
     * - Images should be at least 1200 pixels wide.
     * - Every page must contain at least one image (whether or not you include
     *   markup). Google will pick the best image to display in Search results
     *   based on the aspect ratio and resolution.
     * - Image URLs must be crawlable and indexable.
     * - Images must represent the marked up content. Images must be in .jpg,
     *   .png, or .gif format.
     * - For best results, provide multiple high-resolution images (minimum of
     *   800,000 pixels when multiplying width and height) with the following
     *   aspect ratios: 16x9, 4x3, and 1x1.
     */
    images: string[];
    /**
     * The date and time the article was first published, in ISO 8601 format.
     *
     * @remarks
     *
     * Best practices:
     *
     * - The date shouldn't change over time.
     * - We recommend including the hour information in addition to the day in the
     *   timestamp.
     * - The value for dateModified should be more recent than the value for
     *   datePublished.
     */
    datePublished: string;
    /**
     * The date on which the CreativeWork was created or the item was added to a
     * DataFeed.
     */
    dateCreated?: string;
    /**
     * The date and time the article was most recently modified, in ISO 8601
     * format.
     */
    dateModified?: string;
    /**
     * The name of the author.
     */
    authorName: string;
    /**
     * The type of author for this article.
     *
     * @defaultValue 'Person'
     */
    authorType?: 'Person' | 'Organization';
    /**
     * A short description of the article.
     */
    description: string;
    /**
     * The name of the publisher.
     */
    publisherName: string;
    /**
     * The url of the publisher logo.
     */
    publisherLogo: string;
    /**
     * The actual body of the article.
     */
    body?: string;
    /**
     * Provide
     */
    speakable?: Speakable[];
}

export interface LocalBusinessAddress {
    /** The country. For example, USA. You can also provide the two-letter
     * {@link http://en.wikipedia.org/wiki/ISO_3166-1 ISO 3166-1 alpha-2 country code}.
     */
    addressCountry: string;
    /** The locality in which the street address is, and which is in the region.
     * For example, Mountain View.
     */
    addressLocality?: string;
    /**
     * The region in which the locality is, and which is in the country. For
     * example, California or another appropriate first-level
     * {@link https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country Administrative division}
     */
    addressRegion?: string;
    /**
     * The postal code. For example, 94043.
     */
    postalCode: string;
    /**
     * The post office box number for PO box addresses.
     */
    postOfficeBoxNumber?: string;
    /**
     * The street address. For example, 1600 Amphitheatre Pkwy.
     */
    streetAddress: string;
}

export declare type LocalBusiness = Extract<SchemaLocalBusiness, object>;

export interface LocalBusinessJsonLdProps extends DeferSeoProps, Overrides<LocalBusiness> {
    /**
     * @deprecated
     *
     * Use `overrides['@types']` instead.
     */
    type: LocalBusinessType;
    /**
     * Globally unique ID of the specific business location in the form of a URL.
     *
     * @remarks
     *
     * The ID should be stable and unchanging over time. Google Search treats the
     * URL as an opaque string and it does not have to be a working link. If the
     * business has multiple locations, make sure the `id` is unique for each
     * location.
     */
    id: string;
    /**
     * The name of the business.
     */
    name: string;
    /**
     * The description of the business.
     */
    description: string;
    /**
     * The fully-qualified URL of the specific business location. Unlike the `id`
     * property, this URL property should be a working link.
     */
    url: string;
    /**
     * A business phone number meant to be the primary contact method for
     * customers. Be sure to include the country code and area code in the phone
     * number.
     */
    telephone?: string;
    /**
     * The physical location of the business. Include as many properties as
     * possible. The more properties you provide, the higher quality the result is
     * to users.
     */
    address: LocalBusinessAddress;
    /**
     * Geographic coordinates of the business.
     */
    geo: Geo;
    /**
     * One or more images of the `LocalBusiness`.
     *
     * @remarks
     *
     * Additional image guidelines:
     *
     * Every page must contain at least one image (whether or not you include
     * markup). Google will pick the best image to display in Search results based
     * on the aspect ratio and resolution. Image URLs must be crawlable and
     * indexable. Images must represent the marked up content. Images must be in
     * .jpg, .png, or. gif format. For best results, provide multiple
     * high-resolution images (minimum of 50K pixels when multiplying width and
     * height) with the following aspect ratios: 16x9, 4x3, and 1x1. For example:
     *
     * ```js
     * const props = {
     *   ... //
     *     "image": [
     *     "https://example.com/photos/1x1/photo.jpg",
     *     "https://example.com/photos/4x3/photo.jpg",
     *     "https://example.com/photos/16x9/photo.jpg"
     *   ]
     * };
     * ```
     *
     */
    images: string[];
    /**
     * The opening hours of a certain place.
     *
     * @remarks
     *
     * Use both the validFrom and validThrough properties to define seasonal
     * hours. This example shows a business closed for winter holidays.
     *
     * #### Standard hours
     *
     * Excluding the validFrom and validThrough properties signify that the hours
     * are valid year-round.This example defines a business that is open weekdays
     * from 9am to 9pm, with weekend hours from 10am until 11pm.
     *
     * ```js
     * const props = {
     *   ...other,
     *   openingHoursSpecification: [
     *     {
     *       dayOfWeek: [
     *        "Monday",
     *        "Tuesday",
     *        "Wednesday",
     *        "Thursday",
     *        "Friday"
     *       ],
     *       opens: "09:00",
     *       closes: "21:00"
     *     },
     *     {
     *       dayOfWeek: ["Saturday", "Sunday"],
     *       opens: "10:00",
     *       closes: "23:00"
     *     }
     *   ]
     * }
     * ```
     *
     * #### Late night hours
     *
     * For hours past midnight, define opening and closing hours using a single
     * OpeningHoursSpecification property. This example defines hours from
     * Saturday at 6pm until Sunday at 3am.
     *
     * ```js
     * const props = {
     *   ...other,
     *   openingHoursSpecification: {
     *     opens: "18:00",
     *     closes: "03:00",
     *     dayOfWeek: "Saturday",
     *   }
     * }
     * ```
     *
     * #### All-day hours
     *
     * To show a business as open 24 hours a day, set the open property to "00:00"
     * and the closes property to "23:59".To show a business is closed all day,
     * set both opens and closes properties to "00:00". This example shows a
     * business open all day Saturday and closed all day Sunday.
     *
     *
     * ```js
     * const props = {
     *   ...other,
     *   openingHoursSpecification: [
     *     {
     *       dayOfWeek: "Saturday",
     *       opens: "00:00",
     *       closes: "23:59"
     *     },
     *     {
     *       dayOfWeek: "Sunday",
     *       opens: "00:00",
     *       closes: "00:00"
     *     }
     *   ]
     * }
     * ```
     *
     *
     * #### Seasonal hours
     * ```js
     * const props = {
     *   ...other,
     *   openingHoursSpecification: {
     *     opens: "00:00",
     *     closes: "00:00",
     *     validFrom: "2015-12-23",
     *     validThrough: "2016-01-05"
     *   }
     * }
     * ```
     */
    openingHours?: OpeningHoursSpecification | OpeningHoursSpecification[];
    /**
     * The average rating of the local business based on multiple ratings or
     * reviews.
     */
    rating?: AggregateRating;
    /**
     * The relative price range of a business, commonly specified by either a
     * numerical range.
     *
     * @remarks
     *
     * @example "$10-15" or a normalized number of currency signs (for example,
     * "$$$")
     */
    priceRange?: string;
}

export interface BlogPost {
    /**
     * Headline of the blog post.
     */
    headline: string;
    /**
     * The date and time the article was first published, in ISO 8601 format.
     *
     * @remarks
     *
     * Best practices:
     *
     * - The date shouldn't change over time.
     * - We recommend including the hour information in addition to the day in the
     *   timestamp.
     * - The value for dateModified should be more recent than the value for
     *   datePublished.
     */
    datePublished?: string;
    /**
     * An image URL that is representative of the blog post.
     */
    image?: string;
}

export interface Geo {
    /**
     * The elevation of a location
     * ({@link https://en.wikipedia.org/wiki/World_Geodetic_System WGS 84}).
     * Values may be of the form 'NUMBER UNIT_OF_MEASUREMENT' (e.g., '1,000 m',
     * '3,200 ft') while numbers alone should be assumed to be a value in meters.
     */
    elevation?: string | number;
    /**
     * The latitude of a location.
     *
     * @remarks
     *
     * The precision should be at least 5 decimal places. For example `37.42242`
     * ({@link https://en.wikipedia.org/wiki/World_Geodetic_System WGS 84}).
     */
    latitude: string | number;
    /**
     * The longitude of a location.
     *
     * @remarks
     *
     * The precision should be at least 5 decimal places. For example `-122.08585`
     * ({@link https://en.wikipedia.org/wiki/World_Geodetic_System WGS 84}).
     * */
    longitude: string | number;
}

export interface Speakable {
    /**
     * Addresses content in the annotated pages (such as class attribute).
     *
     * @remarks
     *
     * Use either cssSelector or xPath; don't use both. For example:
     *
     * ```ts
     * cssSelector: ["headline", "summary"]
     * ```
     */
    cssSelector?: string[];
    /**
     * Addresses content using xPaths (assuming an XML view of the content).
     *
     * @remarks
     *
     * Use either
     * cssSelector or xPath; don't use both. For example:
     *
     * ```ts
     * xPath: '/html/head/title'
     * ```
     */
    xpath?: string[];
}

export interface Overrides<GThing extends Thing> {
    /**
     * An overrides object with custom properties for the provided generic
     * `GThing` type.
     *
     * @remarks
     *
     * This is where you can override any provided attributes.
     *
     * For example, to extend an article type to a blog post you would need to
     * pass in the following props.
     *
     * ```tsx
     * const props: ArticleJsonLdProps = {
     *   ...initialProps,
     *   extra: {
     *     '@type': 'BlogPosting', // Makes this a blog post
     *   }
     * };
     * ```
     */
    overrides?: GThing;
}

export declare type LocalBusinessType = LocalBusiness['@type'];
