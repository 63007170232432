import React, { JSXElementConstructor, ReactElement } from 'react';
import { APP_ROOT_ID } from '../../context/constants';
import { Footer } from '../Footer';
import Header from '../Header';
import * as classes from './style.module.css';
import HeadCommon from "../HeadScripts";

// import useDarkMode from 'use-dark-mode';
interface LayoutProps {
    children:
        | ReactElement<any, string | JSXElementConstructor<any>>
        | ReactElement<any, string | JSXElementConstructor<any>>[];
}

export default function Layout(props: LayoutProps): React.ReactElement {
    return (
        <div id={APP_ROOT_ID}>
            <div className={classes.Layout}>
                <Header />
                  <HeadCommon />
                  <main>{props.children}</main>
                <Footer />
            </div>
        </div>
    );
}
